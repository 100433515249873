import React from 'reactn'
import Grid from '@material-ui/core/Grid'
import {ReactComponent as Logo} from '../images/logos/logo.svg'
import Section from '../util/section'
import Summary from "./summary";
import LaunchPad from "./launchpad";

const Hero = () => {
    return (
        <Section name="hero">
            <Grid container spacing={0} alignItems="flex-start">
                <Grid item xs={12} md={4}>
                    <Logo/>
                    <LaunchPad/>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Summary/>
                </Grid>
            </Grid>
        </Section>
    )
}

export default Hero;
