import withStyles from '@material-ui/core/styles/withStyles';
import MDialog from '@material-ui/core/Dialog';

export default withStyles(theme => ({
  paper: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: theme.spacing(1)
    },
  }
}))(MDialog)
