import React from "reactn";
import GA4React from "ga-4-react";

const ga = new GA4React("G-M6M5E9TJWN");
ga.initialize()
    .then(it => {
        it.pageview("home");
    })
    .catch();
export default function GA() {
    return (<span/>)
}
