import React from 'reactn';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const Spinner = ({...props}) => {
    return (
        <Box display="flex" p={1} style={{justifyContent: "center"}}>
            <CircularProgress {...props}/>
        </Box>
    )
}

export default Spinner;
