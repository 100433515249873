import React, {useDispatch, useEffect, useGlobal, useState} from "reactn";
import {scroller} from "react-scroll";
import {formatDistance, parseISO} from "date-fns";
import SvgIcon from "@material-ui/core/SvgIcon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Spinner from "../util/spinner";
import {Body2, Subtitle1, Subtitle2} from "../util/text";
import Section, {SectionHeading} from "../util/section";
import {ReactComponent as TrailIcon} from "../images/icons/trail.svg"
import {GreenLogo} from "../util/logo";
import {useRegions} from "../util/useRegions";


const useHeadCellStyles = makeStyles(() => ({
    button: {
        paddingLeft: 0
    },
    container: {
        flexGrow: 1
    }
}))
function HeadCell({title, field, sort, handleSort, children, ...props}) {
    const c = useHeadCellStyles();
    return (
        <TableCell {...props}>
            <Box display="flex">
                <Grid container alignItems="flex-end" className={c.container}>
                    <Grid item>
                        <Button onClick={() => handleSort(field)} className={c.button}>{title}</Button>
                    </Grid>
                    <Grid item>
                        {sort.field === field && (sort.order === "ASC" ? <ArrowUpwardIcon/> : <ArrowDownwardIcon/>) }
                    </Grid>
                </Grid>
                {children}
            </Box>
        </TableCell>
    )
}

const useTrailTableStyles = makeStyles(theme => ({
    name: {
        fontWeight: 900,
        color: theme.palette.primary.main
    },
    head: {
        textTransform: "uppercase",
        fontWeight: "bold"
    },
    headCell: {
        display: "flex"
    },
    row: {
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.grey["200"]
        }
    },
    pager: {
        // padding: 0,
        backgroundColor: theme.palette.grey["200"],
    },
    footer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        backgroundColor: theme.palette.grey["200"],
    },
    logo: {
        display: "inline-block",
        width: "100px"
    },
    filterButton: {
        minWidth: 65,
        "&.Mui-selected": {
            color: theme.palette.primary.main,
            // color: theme.palette.common.white,
            backgroundColor: theme.palette.common.white
        },
        [theme.breakpoints.down("sm")]: {
            minWidth: 50,
            fontSize: theme.typography.subtitle2.fontSize
        }
    }
}))

export function TrailTable({rows = 10, defaultRegion, withLogo = false, withRegions = true}) {
    const c = useTrailTableStyles();
    const [api] = useGlobal("api");

    const allRegions = useRegions();
    const [regions, setRegions] = useState([]);
    const filterRegions = (evt, newRegions) => {
        setRegions(newRegions);
    }
    useEffect(() => {
        if (allRegions) setRegions(allRegions.map(r => r.id));
    }, [allRegions]);

    const chooseSegment = useDispatch("chooseSegment");
    const jumpToSegment = s => {
        chooseSegment(s);
        scroller.scrollTo("map", {
            duration: 500,
            smooth: true,
            // offset: 200
        });
    }

    const [isWorking, setWorking] = useState(true);

    const [data, setData] = useState({});

    const [offset, setOffset] = useState(0);
    const page = p => {
        setOffset(p*rows);
    }

    const [sort, setSort] = useState({
        field: "groomed",
        order: "DESC"
    });
    const toggleSort = field => {
        setSort({
            field,
            order: sort.field === field ? (sort.order === "ASC" ? "DESC" : "ASC") : sort.field
        })
    }

    useEffect(() => {
        fetchSegments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, offset, regions, rows])

    const fetchSegments = () => {
        setWorking(true);
        fetch(`${api}/segments?offset=${offset}&sort=${sort.field}&order=${sort.order}&limit=${rows}&regions=${regions.join(",")}`)
            .then(rsp => rsp.json())
            .then(data => {
                setData(data);
            })
            .catch(err => console.log(err))
            .finally(() => setWorking(false))
    }

    useEffect(() => {
        if (defaultRegion && allRegions.length > 0) {
            const r = allRegions.find(r => r.short_name === defaultRegion);
            if (r) filterRegions(null, [r.id]);
        }
    }, [defaultRegion, allRegions])
    return (
        <TableContainer component={Paper}>
            <Grid container justify="space-between" alignItems="center" className={c.pager}>
                {withRegions && allRegions &&
                <Grid item>
                    <Box pl={0.5} pt={0.5}>
                        <ToggleButtonGroup size="small" value={regions} onChange={filterRegions}>
                            {allRegions.map(r =>
                                <ToggleButton key={r.id} value={r.id} className={c.filterButton}>
                                    {r.short_name}
                                </ToggleButton>)}
                        </ToggleButtonGroup>
                    </Box>
                </Grid>}
                <Grid item>
                    {isWorking && <Spinner size={24}/>}
                </Grid>
                <Grid item>
                    <TablePagination count={data.total || -1} page={offset/rows} rowsPerPage={rows} rowsPerPageOptions={[]}
                                     onChangePage={(evt, p) => page(p)} component="div"/>
                </Grid>
            </Grid>

            <Table size="small">
                <TableHead className={c.head}>
                    <TableRow>
                        <HeadCell title="Trail" field="name" sort={sort} handleSort={toggleSort} width="50%"/>
                        <HeadCell title="Condition" field="groomed" sort={sort} handleSort={toggleSort} width="50%"/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(data.segments||[]).map(s => <TableRow key={s.id} className={c.row} onClick={() => jumpToSegment(s)}>
                        <TableCell>
                            <Body2 className={c.name}>{s.name}</Body2>
                            {/*<Typography variant="body2" className={c.name}>{s.name}</Typography>*/}
                            <Subtitle1>
                                {s.region}
                            </Subtitle1>
                        </TableCell>
                        <TableCell>
                            {`Groomed ${formatDistance(parseISO(s.groomed), new Date())} ago`}
                            {s.note && <Subtitle2>{s.note}</Subtitle2>}
                            {/*{isLarge && s.note && <div>{s.note}</div>}*/}
                            {/*{!isLarge && s.note && <IconButton size="small" onClick={(evt) => showNote(evt, s.note)}>*/}
                            {/*    <MoreHorizIcon/>*/}
                            {/*</IconButton>}*/}
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
            {withLogo && <Box className={c.footer}>
                <GreenLogo className={c.logo}/>
            </Box>}
        </TableContainer>
    )
}
export default function Trails() {
    return (
        <Section name="trails">
            <SectionHeading title="Trail Conditions" icon={<SvgIcon component={TrailIcon} viewBox="0 0 479.956 479.956"/>}/>
            <TrailTable/>
        </Section>
    )
}

