import React, {useGlobal, useEffect} from 'reactn'
import CountUp from 'react-countup'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import SvgIcon from "@material-ui/core/SvgIcon";
import TodayIcon from '@material-ui/icons/Today'
import DateRangeIcon from '@material-ui/icons/DateRange'
import EventNoteIcon from '@material-ui/icons/EventNote'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Section, {SectionHeading} from '../util/section'
import {XL4} from '../util/text';


const useSummaryCardStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        // alignItems: "center"
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },

    header: {
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.grey["50"],
        borderRight: `1px solid ${theme.palette.grey["100"]}`,
        width: "50%",
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        "& > .MuiCardHeader-avatar": {
            marginRight: 0
        },
        "& > .MuiCardHeader-content": {
            flex: 0
        }
    },
    content: {
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "50%"
    },
    value: {
        color: theme.palette.primary.main,
        fontWeight: "900",
        fontSize: XL4
    }
}));
const SummaryCard = ({title, value, icon}) => {
    const c = useSummaryCardStyles();
    return (
        <Card className={`animated fadeIn ${c.root}`}>
            <CardHeader
                className={c.header}
                avatar={
                    <Avatar className={c.avatar}>
                        {icon}
                    </Avatar>
                }
                title={title}
            />
            <CardContent className={c.content}>
                <p className={c.value}>
                    <CountUp end={value/1000.0} duration={5}/> km
                </p>
            </CardContent>
        </Card>
    )
}
SummaryCard.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
    icon: PropTypes.element
}

const useStyles = makeStyles(theme => ({
    section: {
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.up("md")]: {
            paddingLeft: theme.spacing(8)
        }
    }
}))
const Summary = () => {
    const c = useStyles();
    const [api] = useGlobal("api");
    const [totals, setTotals] = useGlobal("totals");
    const [,setError] = useGlobal("error");

    useEffect(() => {
        if (api && setTotals) {
            fetch(`${api}/totals`)
                .then(rsp => rsp.json())
                .then(data => setTotals(data["totals"]))
                .catch(setError)
        }
    }, [api, setTotals, setError])

    return (
        <Section name="summary" className={c.section}>
            <SectionHeading
                title="Grooming Totals"
                icon={<TimelapseIcon/>}
            />
            {totals &&
            <Grid container justify="flex-start" alignItems="center" spacing={1}>
                <Grid item xs={12} sm={6} lg={4}>
                    <SummaryCard title={"24 Hours"} value={totals["day"]} icon={<TodayIcon/>}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <SummaryCard title={"3 Days"} value={totals["3day"]} icon={<DateRangeIcon/>}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <SummaryCard title={"1 Week"} value={totals["week"]} icon={<SvgIcon>
                        <path d="M17,10H7v2h10V10z M19,3h-1V1h-2v2H8V1H6v2H5C3.9,3,3,3.9,3,5l0,14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5
	C21,3.9,20.1,3,19,3z M19,19H5V8h14V19z"/>
                    </SvgIcon>}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <SummaryCard title={"2 Weeks"} value={totals["2week"]} icon={<EventNoteIcon/>}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <SummaryCard title={"1 Month"} value={totals["month"]} icon={<CalendarTodayIcon/>}/>
                </Grid>
            </Grid>
            }
        </Section>

    )
}

export default Summary;
