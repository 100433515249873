import React, {useState} from 'reactn'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FormHelperText from '@material-ui/core/FormHelperText'
import Box from "@material-ui/core/Box";
import {DatePicker,TimePicker} from '@material-ui/pickers'
import Dialog from '../util/dialog';
import {useIsSmall} from '../util/media'
import {Body1} from '../util/text'

const useDialogStyles = makeStyles(theme => ({
    select: {
        marginBottom: theme.spacing(1),
        width: 256
    },
    heading: {
        marginBottom: theme.spacing(2)
    }
}))
const UpdateDialog = ({condition, groomed, note, open, handleClose}) => {
    const c = useDialogStyles();
    const [cond, setCond] = useState(condition||"good");
    const [date, setDate] = useState(groomed || new Date());
    const [time, setTime] = useState(groomed || new Date());
    const [notes, setNotes] = useState(note||"");

    const isSmall = useIsSmall();
    const setNow = () => {
        setDate(new Date());
        setTime(new Date());
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={open}
            onClose={() => handleClose()}
        >
            <DialogTitle>Add Trail Update</DialogTitle>
            <DialogContent>
                <Body1 gutter={4}>
                    Update trail conditions and grooming information.
                </Body1>
                {/*<H6 gutter={1}>Trail Condition</H6>*/}
                <FormControl variant="outlined" className={c.select} >
                    <InputLabel>Trail Condition</InputLabel>
                    <Select
                        value={cond}
                        onChange={evt => setCond(evt.target.value)}
                        label="Trail Condition"
                    >
                        <MenuItem value="good">Good</MenuItem>
                        <MenuItem value="fair">Fair</MenuItem>
                        <MenuItem value="poor">Poor</MenuItem>
                    </Select>
                </FormControl>
                <Box mb={1}>
                    <TextField
                        label="Notes"
                        variant="outlined"
                        rows={4}
                        value={notes}
                        onChange={evt => setNotes(evt.target.value)}
                        multiline
                        fullWidth
                    />
                </Box>
                <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                        {/*<H6>Grooming Date</H6>*/}
                        <FormHelperText>
                            Groom Date/Time
                        </FormHelperText>
                    </Grid>
                    <Grid item>
                        <Button color="primary" onClick={setNow}>Mark as Groomed Now</Button>
                    </Grid>
                </Grid>
                <Grid container spacing={isSmall?0:1}>
                    <Grid item xs={12} lg={6}>
                        <DatePicker
                            autoOk
                            // orientation="landscape"
                            variant="static"
                            value={date}
                            onChange={setDate}
                        />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <TimePicker
                            autoOk
                            // orientation="landscape"
                            variant="static"
                            value={time}
                            onChange={setTime}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose({
                    groomed: new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds()),
                    condition: cond,
                    note: notes
                })} color="primary" variant="contained">
                    Add
                </Button>
                <Button onClick={() => handleClose()} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}
UpdateDialog.propTypes = {
    condition: PropTypes.string,
    groomed: PropTypes.any,
    note: PropTypes.string,
    open: PropTypes.bool,
    handleClose: PropTypes.func
}

export default UpdateDialog
