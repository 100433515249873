import {STYLE_DARK, STYLE_LIGHT, TrailMap} from "../home/map";
import {useQuery} from "../util/useQuery";
import Widget from "./widget";


export default function MapWidget() {
    const q = useQuery();
    const s = q.get("s") === "dark" ? STYLE_DARK : STYLE_LIGHT;
    const r = q.get("r");
    return (
        <Widget>
            <TrailMap style={s} defaultRegion={r} withLogo={true} withRegions={!r} withChangeStyle={false} />
        </Widget>
    )
}
