import React from "reactn";
import {scroller} from "react-scroll";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MapIcon from '@material-ui/icons/Map';
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles(theme => ({
    tile: {
        padding: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        justifyContent: "center",
        alignItems: "center",
        width: 128,
        height: 128,
        "& > .MuiTypography-root": {
            fontWeight: 900,
            textAlign: "center",
            textTransform: "uppercase",
            color: theme.palette.primary.main,
            textShadow: `0px 0px 1px ${theme.palette.common.white}`,
            zIndex: 100
        },
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.grey["100"]
        }
    },
    cover: {
        position: "absolute",
        backgroundColor: theme.palette.grey["900"],
        opacity: "0%",
        width: "100%",
        height: "100%",
        borderRadius: theme.shape.borderRadius,
        "&:hover": {
            opacity: "10%",
        }
    },
    button: {
        lineHeight: "1.35rem",
    },
    item: {
        textAlign: "center"
    }
}));
export default function LaunchPad() {
    const c = useStyles();
    const goToMap = () => {
        scroller.scrollTo('map', {
            duration: 500,
            smooth: true
        })
    }
    return (
        <Box mt={2}>
            <Paper elevation={1} className={c.tile} onClick={goToMap}>
                <MapIcon fontSize="large"/>
                <Typography variant="body2">Grooming Map</Typography>
            </Paper>
            <Grid container spacing={2} justify="flex-start">
                <Grid item md={4} className={c.item}>

                </Grid>
                <Grid item md={8}>

                </Grid>
                {/*<Grid item className={c.item}>*/}
                {/*    <Paper elevation={1} className={c.tile} onClick={goToMap}>*/}
                {/*        <EmailIcon fontSize="large"/>*/}
                {/*        <Typography variant="body2">Subscribe for Updates</Typography>*/}
                {/*    </Paper>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={6} className={c.item}>*/}
                {/*    <Paper elevation={1} className={c.tile} onClick={goToMap}>*/}
                {/*        <div className={c.cover}/>*/}
                {/*    </Paper>*/}
                {/*    <Button color="primary" className={c.button}>*/}
                {/*        Subscribe for Updates*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
            </Grid>
        </Box>
    )
}
