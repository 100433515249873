import React, {useGlobal} from 'reactn'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles'
import {createMuiTheme} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import {ThemeProvider} from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'
import DashPage from './app/dash';
import UsersPage from './app/users';
import HomePage from './home/page';
import EmbedPage from "./embed/page";
import {StravaPage} from "./app/strava";
import InvitePage from "./app/invite";
import MapWidget from "./widgets/map";
import TableWidget from "./widgets/table";


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4d8c40',
    },
  },
  typography: {
    fontSize: 16,
    h1: {
      fontWeight: 900,
    },
    body1: {
      fontSize: '1.25rem',
      // fontWeight: 300,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    body2: {
      fontSize: '1rem',
      // fontWeight: 300,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    subtitle1: {
      fontSize: '1rem',
      // fontWeight: 400
    },
    subtitle2: {
      fontSize: '0.875rem',
      // fontWeight: 300
    },
  },
})

const CssOverride = withStyles(theme => ({
  '@global': {
    '.MuiAlert-message': {
      padding: `${theme.spacing(0.5)}px 0 !important`,
    },
    '.MuiGrid-container': {
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        margin: '0 !important',
      },
    },
    '.MuiButton-outlinedPrimary': {
      borderWidth: '2px !important',
    },
    'h1,h2,h3,h4,h5,h6': {
      margin: 0,
    },
    p: {
      margin: 0,
    },
    a: {
      color: blue['400'],
      textDecoration: 'none',
      fontWeight: '500',
      '&:hover': {
        color: blue['700'],
        textDecoration: 'underline',
      },
    },
  },
}))(() => null)


const App = () => {
  const [user,] = useGlobal("user");
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CssOverride />
        <Router>
          <Switch>
            <Route exact path="/">
              <HomePage/>
            </Route>
            <Route exact path="/embed">
              <EmbedPage/>
            </Route>
            {user && user.admin &&
            <Route path="/app/users">
              <UsersPage/>
            </Route>}
            <Route path="/app/strava">
              <StravaPage/>
            </Route>
            <Route path="/app">
              <DashPage/>
            </Route>
            <Route path="/invite/:invite">
              <InvitePage/>
            </Route>
            <Route path="/widgets/map">
              <MapWidget/>
            </Route>
            <Route path="/widgets/table">
              <TableWidget/>
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
  )
}

export default App;

