import React from 'reactn'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const XS = '0.75rem'
export const SM = '0.85rem'
export const BASE = '1rem'
export const LG = '1.125rem'
export const XL = '1.25rem'
export const XL2 = '1.5rem'
export const XL3 = '1.875rem'
export const XL4 = '2rem'
export const XL5 = '2.5rem'
export const XL6 = '3rem'
// const XL7 = '3.5rem'

const LEADING_SNUG = 1.375;

const useGutterStyles = makeStyles((theme) => ({
    g1: {marginBottom: theme.spacing(1)},
    g2: {marginBottom: theme.spacing(2)},
    g3: {marginBottom: theme.spacing(4)},
    g4: {marginBottom: theme.spacing(4)},
}))

const gut = (v,g) => ({
    [g.g1]: v === 1,
    [g.g2]: v === 2,
    [g.g3]: v === 3,
    [g.g4]: v === 4
})

const useH1Styles = makeStyles(theme => ({
    root: {
        fontSize: XL5,
        lineHeight: LEADING_SNUG,
        [theme.breakpoints.up("lg")]: {fontSize: XL6}
    }
}))
export const H1 = ({children, gutter, className, ...props}) => {
    const c = useH1Styles();
    const g = useGutterStyles();

    return (
        <h1 className={clsx(c.root, gut(gutter, g), className)} {...props}>
            {children}
        </h1>
    )
}
H1.propTypes = {
    children: PropTypes.node.isRequired,
    gutter: PropTypes.number,
    className: PropTypes.any,
}

const useH2Styles = makeStyles(theme => ({
    root: {
        fontSize: XL4,
        lineHeight: LEADING_SNUG,
        [theme.breakpoints.up("lg")]: {fontSize: XL5}
    }
}))
export const H2 = ({children, gutter, className, ...props}) => {
    const c = useH2Styles();
    const g = useGutterStyles();

    return (
        <h2 className={clsx(c.root, gut(gutter, g), className)} {...props}>
            {children}
        </h2>
    )
}
H2.propTypes = {
    children: PropTypes.node.isRequired,
    gutter: PropTypes.number,
    className: PropTypes.any,
}

const useH3Styles = makeStyles(theme => ({
    root: {
        fontSize: XL3,
        lineHeight: LEADING_SNUG,
        [theme.breakpoints.up("lg")]: {fontSize: XL4}
    }
}))
export const H3 = ({children, gutter, className, ...props}) => {
    const c = useH3Styles();
    const g = useGutterStyles();
    return (
        <h3 className={clsx(c.root, gut(gutter, g), className)} {...props}>
            {children}
        </h3>
    )
}
H3.propTypes = {
    children: PropTypes.node.isRequired,
    gutter: PropTypes.number,
    className: PropTypes.any,
}

const useH4Styles = makeStyles(theme => ({
    root: {
        fontSize: XL2,
        lineHeight: LEADING_SNUG,
        [theme.breakpoints.up("lg")]: {fontSize: XL3}
    }
}))
export const H4 = ({children, gutter, className, ...props}) => {
    const c = useH4Styles();
    const g = useGutterStyles();
    return (
        <h4 className={clsx(c.root, gut(gutter, g), className)} {...props}>
            {children}
        </h4>
    )
}
H4.propTypes = {
    children: PropTypes.node.isRequired,
    gutter: PropTypes.number,
    className: PropTypes.any,
}

const useH5Styles = makeStyles(theme => ({
    root: {
        fontSize: XL,
        lineHeight: LEADING_SNUG,
        [theme.breakpoints.up("md")]: {fontSize: XL2},
        [theme.breakpoints.up("lg")]: {fontSize: XL3}
    }
}))
export const H5 = ({children, gutter, className, ...props}) => {
    const c = useH5Styles();
    const g = useGutterStyles();
    return (
        <h5 className={clsx(c.root, gut(gutter, g), className)} {...props}>
            {children}
        </h5>
    )
}
H5.propTypes = {
    children: PropTypes.node.isRequired,
    gutter: PropTypes.number,
    className: PropTypes.string,
}

const useH6Styles = makeStyles(theme => ({
    root: {
        fontSize: BASE,
        lineHeight: LEADING_SNUG,
        [theme.breakpoints.up("md")]: {fontSize: LG},
        [theme.breakpoints.up("lg")]: {fontSize: XL}
    }
}))
export const H6 = ({children, gutter, className, ...props}) => {
    const c = useH6Styles();
    const g = useGutterStyles();
    return (
        <h6 className={clsx(c.root, gut(gutter, g), className)} {...props}>
            {children}
        </h6>
    )
}
H6.propTypes = {
    children: PropTypes.node.isRequired,
    gutter: PropTypes.number,
    className: PropTypes.any,
}

const useBody1Styles = makeStyles(theme => ({
    root: {
        fontSize: LG,
        lineHeight: LEADING_SNUG,
        [theme.breakpoints.up("md")]: {fontSize: XL},
        // [theme.breakpoints.up("lg")]: {fontSize: XL2}
    }
}))
export const Body1 = ({children, gutter, className, ...props}) => {
    const c = useBody1Styles();
    const g = useGutterStyles();
    return (
        <p className={clsx(c.root, gut(gutter, g), className)} {...props}>
            {children}
        </p>
    )
}
Body1.propTypes = {
    children: PropTypes.node.isRequired,
    gutter: PropTypes.number,
    className: PropTypes.any,
}

const useBody2Styles = makeStyles(theme => ({
    root: {
        fontSize: BASE,
        [theme.breakpoints.up("md")]: {fontSize: LG},
    }
}))
export const Body2 = ({children, gutter, className, ...props}) => {
    const c = useBody2Styles();
    const g = useGutterStyles();
    return (
        <p className={clsx(c.root, gut(gutter, g), className)} {...props}>
            {children}
        </p>
    )
}
Body2.propTypes = {
    children: PropTypes.node.isRequired,
    gutter: PropTypes.number,
    className: PropTypes.any,
}

const useSubtitle1Styles = makeStyles(theme => ({
    root: {
        fontSize: SM,
        [theme.breakpoints.up("md")]: {fontSize: BASE},
    }
}))
export const Subtitle1 = ({children, gutter, className, ...props}) => {
    const c = useSubtitle1Styles();
    const g = useGutterStyles();

    return (
        <p className={clsx(c.root, gut(gutter, g), className)} {...props}>
            {children}
        </p>
    )
}
Subtitle1.propTypes = {
    children: PropTypes.node.isRequired,
    gutter: PropTypes.number,
    className: PropTypes.any,
}

const useSubtitle2Styles = makeStyles(theme => ({
    root: {
        fontSize: XS,
        [theme.breakpoints.up("md")]: {fontSize: SM},
    }
}))
export const Subtitle2 = ({children, gutter, className, ...props}) => {
    const c = useSubtitle2Styles();
    const g = useGutterStyles();
    return (
        <p className={clsx(c.root, gut(gutter, g), className)} {...props}>
            {children}
        </p>
    )
}
Subtitle2.propTypes = {
    children: PropTypes.node.isRequired,
    gutter: PropTypes.number,
    className: PropTypes.any,
}

export const CodeBlock = ({children, ...props}) => {
    return (
        <pre style={{
            whiteSpace: 'pre-wrap',
            fontFamily: 'monospace',
            lineHeight: '1.5rem'
        }} {...props}>{children}</pre>
    )
}
CodeBlock.propTypes = {
    children: PropTypes.node.isRequired
}
