import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const useIsSmall = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down("sm"));
}

export const useIsNotSmall = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up("sm"));
}

export const useIsLarge = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up("lg"));
}
