import React, {useEffect, useRef, useState, useGlobal} from 'reactn'
import {format, formatDistance, parseISO} from 'date-fns'
import {merge} from 'lodash'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import MapIcon from '@material-ui/icons/Map'
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid";
import yellow from "@material-ui/core/colors/yellow"
import red from "@material-ui/core/colors/red"
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import buffer from '@turf/buffer';
import {isSomething} from '../util/functions'
import Section, {SectionHeading} from '../util/section'
import {Body2, Subtitle2} from '../util/text'
import {ReactComponent as SolidIcon} from '../images/icons/solid.svg'
import {ReactComponent as DashedIcon} from '../images/icons/dashed.svg'
import {useRegions} from "../util/useRegions";
import {useLocalStorage} from "../util/useLocalStorage";
import "./map.css";
import {GreenLogo} from "../util/logo";
import usePrevious from "../util/usePrevious";



mapboxgl.accessToken = "pk.eyJ1IjoiY2FtYmEiLCJhIjoiY2tqYzcycWVpMHJncDJxbDM0ZXN0eW9lcyJ9._Zytz_BSgXlK3viYAQN12g";

const segmentColor = days => {
    if (days <= 0) return green["600"];
    if (days > 0 && days <= 3) return '#7cb342';
    if (days > 3 && days <= 7) return yellow["600"];
    if (days > 7 && days <= 14) return orange["600"];
    return red["600"];
}

const useLegendStyles = makeStyles((theme) => ({
    axis: {
        textTransform: "uppercase",
        // textAlign: "center"
    },
    label: {
        // textAlign: "center"
        fontWeight: 900,
        textTransform: "uppercase",
        fontSize: "small"
    },
    shade: {
        width: theme.spacing(8),
        height: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        }
    },
    row: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        width: theme.spacing(12),
        color: theme.palette.grey["800"],
        fill: "currentColor",
        // marginRight: theme.spacing(2)
    },
    center: {
        textAlign: "center"
    }
}))

const Legend = ({...props}) => {
    const c = useLegendStyles();
    return (
        <Box {...props}>
            <Box m={2}>
                <Subtitle2 className={c.axis}>Last Groomed</Subtitle2>
                <Box className={c.row}>
                    <SolidIcon className={c.icon} style={{color: segmentColor(0)}}/>
                    <Body2>Today</Body2>
                </Box>
                <Box className={c.row}>
                    <SolidIcon className={c.icon} style={{color: segmentColor(3)}}/>
                    <Body2>1 - 3 Days</Body2>
                </Box>
                <Box className={c.row}>
                    <SolidIcon className={c.icon} style={{color: segmentColor(7)}}/>
                    <Body2>4 - 7 Days</Body2>
                </Box>
                <Box className={c.row}>
                    <SolidIcon className={c.icon} style={{color: segmentColor(14)}}/>
                    <Body2>7 - 14 Days</Body2>
                </Box>
                <Box className={c.row}>
                    <SolidIcon className={c.icon} style={{color: segmentColor(24)}}/>
                    <Body2>14+ Days</Body2>
                </Box>
            </Box>
            <Box m={2}>
                <Subtitle2 className={c.axis}>Trail Condition</Subtitle2>
                <Box className={c.row}>
                    <SolidIcon className={c.icon} />
                    <Body2>Good</Body2>
                </Box>
                <Box className={c.row}>
                    <DashedIcon className={c.icon} />
                    <Body2>See Notes</Body2>
                </Box>
            </Box>
        </Box>
    )
}

export class ContrastControl {
    constructor(style, setStyle) {
        this.style = style;
        this.setStyle = setStyle;
    }

    onAdd(map){
        this.map = map;

        const button = document.createElement("button");
        button.className = "mapbox-gl-draw_ctrl-draw-btn contrast-ctrl";

        button.style.setProperty("background-image", 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' x=\'0px\' y=\'0px\' viewBox=\'0 0 512 512\'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath d=\'M256,84c-95,0-172,77-172,172s77,172,172,172s172-77,172-172S351,84,256,84z M256,406.5c-83.1,0-150.5-67.4-150.5-150.5 S172.9,105.5,256,105.5S406.5,172.9,406.5,256S339.1,406.5,256,406.5z\'/%3E%3Cpath d=\'M256,137.8c-5.9,0-10.8,4.8-10.8,10.8v215c0,5.9,4.8,10.8,10.8,10.8c65.3,0,118.2-52.9,118.2-118.2 S321.3,137.8,256,137.8z\'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")');
        button.title = "Toggle Light/Dark Map";

        button.addEventListener("click", () => {
            const s = this.style === STYLE_LIGHT ? STYLE_DARK : STYLE_LIGHT;
            if (this.setStyle) this.setStyle(s);
            this.style = s;
        });

        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this.container.appendChild(button);

        return this.container;
    }

    onRemove(){
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
    }
}

export class LegendControl {
    constructor(show, onChange) {
        this.show = show;
        this.onChange = onChange;
    }

    onAdd(map){
        this.map = map;

        const button = document.createElement("button");
        button.className = "mapbox-gl-draw_ctrl-draw-btn legend-ctrl";

        button.style.setProperty("background-image", 'url("data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!-- Generator: Adobe Illustrator 25.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version=\'1.1\' id=\'Capa_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 60.1 60.1\' style=\'enable-background:new 0 0 60.1 60.1;\' xml:space=\'preserve\'%3E%3Cg%3E%3Cpath d=\'M46.7,43.5H22c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8h24.8c1,0,1.8,0.8,1.8,1.8S47.7,43.5,46.7,43.5z\'/%3E%3Cpath d=\'M46.7,31.9H22c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8h24.8c1,0,1.8,0.8,1.8,1.8C48.6,31.1,47.7,31.9,46.7,31.9z\'/%3E%3Cpath d=\'M46.7,20.3H22c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8h24.8c1,0,1.8,0.8,1.8,1.8S47.7,20.3,46.7,20.3z\'/%3E%3Ccircle cx=\'14\' cy=\'18.6\' r=\'2.5\'/%3E%3Ccircle cx=\'14\' cy=\'30.1\' r=\'2.5\'/%3E%3Ccircle cx=\'14\' cy=\'41.5\' r=\'2.5\'/%3E%3C/g%3E%3C/svg%3E%0A")');
        button.title = "Toggle Legend";

        button.addEventListener("click", () => {
            this.show = !this.show;
            this.onChange(this.show);
            button.classList.toggle("selected");
        });

        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this.container.appendChild(button);

        return this.container;
    }

    onRemove(){
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
    }
}

export const STYLE_LIGHT = "mapbox://styles/camba/ckv1pdclo2mcp14s0lw2fo7n3";
export const STYLE_DARK = "mapbox://styles/camba/ckx81p1w50dwe15ru3xu8sjqk";

const useMapStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        height: "100%",
        position: "relative"
    },
    upperLeft: {
        position: "absolute",
        top: theme.spacing(1),
        left: theme.spacing(1),
        zIndex: 10
    },
    regions: {
        width: "initial",
    },
    legend: {
        position: "absolute",
        zIndex: 10,
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(0.5),
        // justifyContent: "space-between"
    },
    regionButton: {
        padding: 0,
        backgroundColor: theme.palette.grey["100"],
        width: "100%"
    },
    logo: {
        position: "absolute",
        width: "100px",
        right: theme.spacing(1),
        bottom: theme.spacing(1),
        zIndex: 10
    },
    spinner: {
        marginTop: theme.spacing(1)
    },
}));

export function TrailMap({style = STYLE_LIGHT, setStyle, defaultRegion, withLogo = false,
    withRegions = true, withChangeStyle = true}) {

    const [api] = useGlobal("api");
    const [segment] = useGlobal("segment");
    const prevStyle = usePrevious(style);
    const regions = useRegions();

    const c = useMapStyles();
    const ref = useRef();
    const [map, setMap] = useState();
    const [showLegend, setShowLegend] = useState(false);
    const [isWorking, setWorking] = useState(false);

    const jumpTo = region => {
        if (map) {
            map.flyTo(region.area);
        }
    }

    useEffect(() => {
        if (ref.current) {
            const map = new mapboxgl.Map({
                container: ref.current,
                style: style,
                center: [-115.400551,51.108004],
                zoom: 12,
                attributionControl: false
            });
            setMap(map);

            map.addControl(new mapboxgl.FullscreenControl());
            map.addControl(new mapboxgl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            }));
            if (withChangeStyle) map.addControl(new ContrastControl(style, newStyle => {
                if (setStyle) setStyle(newStyle);
            }));
            map.addControl(new LegendControl(showLegend, setShowLegend));

            const hoverPopup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            });

            const renderPopup = f => {
                const g = isSomething(f.properties.groomed) ?
                    `Groomed <em>${formatDistance(parseISO(f.properties.groomed), new Date())} ago</em> on <i>${format(parseISO(f.properties.groomed), "EEE, LLL do 'at' HH:mm")}</i>.` :
                    "No grooming information available."
                const n = isSomething(f.properties.note) ? f.properties.note : "";
                return `
                  <div class="fb-popup">
                        <h2>${f.properties.name}</h2>
                        <h4>
                          ${g}
                        </h4>
                        <p>
                          ${n}
                        </p>
                  </div>
                `
            };

            const initMapData = () => {
                setWorking(true);
                fetch(`${api}/map/segments`)
                    .then(rsp => rsp.json())
                    .then(segments => {
                        map.addSource("segments", {
                            type: "geojson",
                            data: {
                                type: "FeatureCollection",
                                features: segments.features.map(f => {
                                    if (!f.properties.note) delete f.properties.note;
                                    return f;
                                })
                            }
                        });

                        map.addSource("segment-buffers", {
                            type: "geojson",
                            data: {
                                type: "FeatureCollection",
                                features: segments.features.map(f => ({
                                    ...f,
                                    geometry: buffer(f, 0.05).geometry
                                }))
                            }
                        })

                        map.addLayer({
                            id: "segment-highlights",
                            type: "line",
                            minzoom: 10,
                            source: "segments",
                            layout: {
                                "line-cap": "round",
                            },
                            paint: {
                                "line-color": style === STYLE_LIGHT ? "#333333" : "#ffffff",
                                'line-opacity': [
                                    'case',
                                    ['boolean', ['feature-state', 'hover'], false],
                                    1,
                                    0
                                ],
                                "line-blur": 10,
                                "line-gap-width": 0.5,
                                "line-width": ["interpolate", ["linear"], ["zoom"],
                                    13, 4,
                                    14, 7,
                                    16, 10],
                            }
                        });

                        const baseConfig = {
                            type: "line",
                            minzoom: 10,
                            source: "segments",
                            layout: {
                                "line-cap": "round",
                            },
                            paint: {
                                // "line-color": red["600"],
                                // "line-pattern": [],
                                "line-color": [
                                    "case",
                                    ["==", ["get", "groomed_days"], 0], segmentColor(0),
                                    ["all", [">", ["get", "groomed_days"], 0], ["<=", ["get", "groomed_days"], 3]], segmentColor(3),
                                    ["all", [">", ["get", "groomed_days"], 3], ["<=", ["get", "groomed_days"], 7]], segmentColor(7),
                                    ["all", [">", ["get", "groomed_days"], 7], ["<=", ["get", "groomed_days"], 14]], segmentColor(14),
                                    segmentColor(24)
                                ],
                                "line-width": ["interpolate", ["linear"], ["zoom"],
                                    13, 2,
                                    14, 4,
                                    16, 6],
                                // 13, 4,
                                // 14, 6,
                                // 16, 8],
                                "line-blur": 1,
                            }
                        }
                        map.addLayer({
                            id: 'segments',
                            // filter: ["==", "condition", "good"],
                            filter: ["!", ["has", "note"]],
                            ...merge({}, baseConfig, {})
                        });
                        map.addLayer({
                            id: 'segments-with-note',
                            filter: ["has", "note"],
                            ...merge({}, baseConfig, {
                                layout: {
                                    "line-cap": "butt",
                                },
                                paint: {
                                    "line-dasharray": [2,0.75]
                                }
                            })
                        });

                        map.addLayer({
                            id: "segment-labels",
                            type: "symbol",
                            source: "segments",
                            minzoom: 13,
                            layout: {
                                // "symbol-sort-key": 0,
                                "symbol-placement": "line",
                                "symbol-spacing": 500,
                                "text-field": ['get', 'name'],
                                "text-size": 14,
                                // "text-offset": [0,1],
                                "text-font": ["DIN Pro Regular"],
                                "text-letter-spacing": 0.01,
                                "text-max-angle": 90,
                                "text-allow-overlap": false,
                                "text-ignore-placement": false,
                            },
                            paint: {
                                "text-halo-color": "#fff",
                                "text-halo-width": 2,
                                "text-halo-blur": 1
                            }
                        });
                        map.addLayer({
                            id: "segment-buffers",
                            type: "fill",
                            source: "segment-buffers",
                            // "layout": {},
                            paint: {
                                "fill-color": '#ffffff',
                                "fill-opacity": 0,
                            }
                        });

                        // map.on("click", "segment-buffers", evt => {
                        //     map.removeFeatureState({source: 'segments'}, 'hover');
                        //
                        //     const f = evt.features[0];
                        //     const html = renderPopup(f);
                        //     clickPopup.setLngLat(evt.lngLat)
                        //         .setHTML(html)
                        //         .addTo(map);
                        // })

                        setMap(map);
                    })
                    .catch(error => {
                        console.log(error);
                    })
                    .finally(() => setWorking(false));
            }

            map.on("load", initMapData);
            map.on("stylechange", initMapData);

            let fid = null;
            map.on("mousemove", "segment-buffers", evt => {
                if (evt.features.length > 0) {
                    const f = evt.features[0];
                    map.getCanvas().style.cursor = 'pointer';
                    fid = f.id;

                    map.removeFeatureState({source: 'segments'});
                    map.setFeatureState(
                        { source: 'segments', id: fid },
                        { hover: true }
                    );

                    hoverPopup.setLngLat(evt.lngLat)
                        .setHTML(renderPopup(f))
                        .addTo(map);
                }
            });
            map.on("mouseleave", "segment-buffers", () => {
                map.getCanvas().style.cursor = '';
                if (fid !== null) {
                    map.setFeatureState(
                        { source: 'segments', id: fid },
                        { hover: false }
                    );
                }
                fid = null;
                hoverPopup.remove();
            });

            // map.on("move", function() {
            //     setXYZ(`${map.getCenter().lng},${map.getCenter().lat},${map.getZoom()}`);
            // });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, api])

    useEffect(() => {
        if (segment && map) {
            const segments = map.getSource("segments");
            if (segments) {
                const s = segments._data.features.find(f => f.id === segment.id);
                if (s) {
                    // map.setFeatureState(
                    //     { source: 'segments', id: s.id },
                    //     { hover: true }
                    // );
                    const coordinates = s.geometry.coordinates;
                    const bounds = new mapboxgl.LngLatBounds(
                        coordinates[0],
                        coordinates[0]
                    );
                    for (const coord of coordinates) {
                        bounds.extend(coord);
                    }
                    map.fitBounds(bounds, {
                        padding: 20
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [segment]);

    useEffect(() => {
        if (regions && defaultRegion && map) {
             const r = regions.find(it => it.short_name.toLowerCase() === defaultRegion.toLowerCase());
             if (r) {
                 jumpTo(r);
             }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultRegion, regions, map]);

    useEffect(() => {
        if (map && style && style !== prevStyle) {
            map.setStyle(style);
            setTimeout(() => {
                map.fire("stylechange");
            }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [style, map]);

    return (
        <div ref={ref} className={c.root}>
            <div className={c.upperLeft}>
                {withRegions && <Grid container direction="column" className={c.regions} spacing={1}>
                    {(regions||[]).map(r => <Grid item key={r.id}>
                        <Button color="primary" variant="outlined" onClick={() => jumpTo(r)} className={c.regionButton} size="small">
                            {r.short_name}
                        </Button>
                    </Grid>)}
                </Grid>}
                {isWorking && <CircularProgress size={24} color="primary" className={c.spinner}/>}
            </div>

            {showLegend && <Legend className={c.legend}/>}
            {withLogo && <GreenLogo className={c.logo}/>}
        </div>
    );
}

const useStyles = makeStyles(() => ({
    map: {
        height: "728px",
        maxHeight: "90vh",
    }
}))
export default function TrailMapSection() {
    const c = useStyles();
    const [style, setStyle] = useLocalStorage("camba.fatbike.style", STYLE_LIGHT);
    return (
        <Section name="map">
            <SectionHeading title="Trail Map" icon={<MapIcon/>}>
            </SectionHeading>
            <Box className={c.map}>
                <TrailMap style={style} setStyle={setStyle}/>
            </Box>

        </Section>
    );
}


