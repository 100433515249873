import React from "reactn";
import SEO from "../util/seo";
import Top from "../home/top";
import Hero from "./hero";
import Builder from "./builder";

const EmbedPage = () => {
    return <>
        <SEO title="Fat Bike Conditions"/>
        <Top/>
        <Hero/>
        <Builder/>
    </>
}

export default EmbedPage
