import React, {useEffect, useState} from "reactn";
import {scroller} from "react-scroll";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Fab from "@material-ui/core/Fab";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => ({
  fab: {
      position: "fixed",
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      zIndex: 100,
      transition: "opacity 0.5s ease-in",
      [theme.breakpoints.down("sm")]: {
          bottom: theme.spacing(1),
          right: theme.spacing(1),
      }
  }
}))
export default function Floater() {
    const c = useStyles();
    const [show, setShow] = useState(false);

    useEffect(() => {
        const onScroll = () => setShow(window.scrollY > 250);
        onScroll();

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const handleClick = () => {
        scroller.scrollTo('hero', {
            duration: 500,
            smooth: true
        });
    }


    return (
        <Fab color="primary" aria-label="Back to Top of Page" className={c.fab} onClick={handleClick} size="small"
            style={{opacity: show ? 1 : 0}}>
            <ExpandLessIcon />
        </Fab>
    )
}
