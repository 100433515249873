import React, {useState, useGlobal, useDispatch} from 'reactn'
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import Button from '@material-ui/core/Button';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone'
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone'
import Section from '../util/section'
import {Body1} from "../util/text"
import {CircularProgress} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";



const useStyles = makeStyles(theme => ({
    section: {
        textAlign: "center"
    },
    paper: {
        display: "inline-block",
        padding: `${theme.spacing(8)}px ${theme.spacing(4)}px`
    },
    form: {
        textAlign: "center"
    }
}));
const Login = () => {
    const [api] = useGlobal("api")
    const c = useStyles();
    const login = useDispatch("login");
    const [isWorking, setWorking] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [email, setEmail] = useState("");
    const [passwd, setPasswd] = useState("");

    const handleLogin = () => {
        setFailed(false);
        setWorking(true);
        fetch(`${api}/auth`, {
            method: "POST",
            body: JSON.stringify({email, passwd}),
            headers: {"Content-Type": "application/json"}
        })
        .then(rsp => {
            if (rsp.status !== 200) {
                throw Error(rsp.status);
            }
            return rsp.json()
        })
        .then(user => {
            login(user).then(() => setWorking(false));
        })
        .catch(() => {
            setFailed(true);
            setWorking(false);
        })
    }
    return (
        <Section name="login" className={c.section}>
            <Paper variant="outlined" className={c.paper}>
                {isFailed &&
                    <Box mb={2}>
                        <Alert severity="error" variant="filled">
                            <AlertTitle>Login Failed</AlertTitle>
                            Sorry, you specified an invalid login. Please try again.
                        </Alert>
                    </Box>
                }
                <form className={c.form}>
                    <Box mb={4}>
                        <Body1>Please log in with your email address and password.</Body1>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="Email" variant="outlined" value={email} onChange={evt => setEmail(evt.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailTwoToneIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box mb={4}>
                        <TextField
                            type="password" label="Password" variant="outlined" value={passwd} onChange={evt => setPasswd(evt.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockTwoToneIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Button variant="outlined" color="primary" disabled={!(email&&passwd) || isWorking} onClick={handleLogin}
                        startIcon={isWorking?<CircularProgress size={24}/>:null}>
                        Login
                    </Button>
                </form>
            </Paper>
        </Section>
    )
}

export default Login;
