import {useState} from "reactn";
import {useEffect} from "react";
import Box from "@material-ui/core/Box";
import Spinner from "../util/spinner";

export default function Widget({children}) {
    const [size, setSize] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const w = params.get("w") || "100%"
        const h = params.get("h") || "100vh";

        setSize([w,h]);
    }, []);

    return (
        size ? <Box width={size[0]} height={size[1]}>{children}</Box> : <Spinner/>
    )
}
