import React, {cloneElement} from 'reactn'
import { Element } from 'react-scroll'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Container from '@material-ui/core/Container'
import Grid from "@material-ui/core/Grid";
import {H4} from "./text";


const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.up('lg')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    gutter: {
        marginBottom: theme.spacing(2),
    },
}))
const Section = ({ children, name, gutter, className }) => {
    const c = useStyles()
    return (
        <Element name={name}>
            <Container
                maxWidth="lg"
                className={clsx(c.root, { [c.gutter]: gutter }, className)}
            >
                {children}
            </Container>
        </Element>
    )
}

Section.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
    gutter: PropTypes.bool,
    className: PropTypes.any,
}

Section.defaultProps = {
    gutter: true,
}
export default Section

const useHeadingStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        // [theme.breakpoints.up('md')]: {
        //     marginBottom: theme.spacing(4)
        // },
    },
    right: {
        flexGrow: 1,
        paddingLeft: theme.spacing(2)
    },
    title: {
        textTransform: "uppercase"
    },
    line: {
        marginTop: 0,
        color: theme.palette.primary.main,
        borderWidth: 2,
        borderStyle: 'solid',
    },
    children: {
        flexGrow: 1
    }
}))
export const SectionHeading = ({ icon, title, children }) => {
    const c = useHeadingStyles()
    return (
        <div className={c.root}>
            {icon && cloneElement(icon, {style: {fontSize: "3.5rem"}})}
            <div className={c.right}>
                <Grid container alignItems="flex-end">
                    <Grid item>
                        <H4 className={c.title}>{title}</H4>
                    </Grid>
                    <Grid item className={c.children}>
                        {children}
                    </Grid>
                </Grid>
                <hr className={c.line} />
            </div>
        </div>
    )
}

SectionHeading.propTypes = {
    icon: PropTypes.object,
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
}
