import {TrailTable} from "../home/trails";
import Widget from "./widget";
import {useQuery} from "../util/useQuery";

export default function TableWidget() {
    const q = useQuery();
    const r = q.get("r");
    return (
        <Widget>
            <TrailTable rows={q.get("n")||10} defaultRegion={r} withRegions={!r} withLogo={true}/>
        </Widget>
    )
}
