import React from 'reactn'
import Top from './top'
import Hero from './hero'
import Map from './map'
import Advisories from './advisories'
import Trails from './trails'
import SEO from "../util/seo";
// import BetaNotice from "./beta";
import Sponsor from "./sponsors";
import GA from "./ga";
import Floater from "./floater";

const HomePage = () => {
    return (
        <>
            <SEO title="Fat Bike Conditions"/>
            <GA/>
            <Top/>
            {/*<BetaNotice/>*/}
            <Hero/>
            <Advisories/>
            <Trails/>
            <Map/>
            <Sponsor/>
            <Floater/>
        </>
    )
}

export default HomePage;
