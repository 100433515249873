import React from "reactn";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import Grid from "@material-ui/core/Grid";
import RotaryLogo from "../images/logos/rotary-canmore.png"
import Button from "@material-ui/core/Button";
import Section, {SectionHeading} from "../util/section";
import {Body1, H6} from "../util/text";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles(() => ({
    logo: {
        width: "100%",
        height: "auto"
    },
    like: {
        flex: "1"
    }
}))
export default function Sponsor() {
    const c = useStyles();
    return (
        <Section name="sponsors">
            <SectionHeading title="Supporters" icon={<GroupWorkIcon/>}/>

            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={4} >
                    <img src={RotaryLogo} className={c.logo} alt="Rotary Club of Canmore Logo"/>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <H6>Thanks to the Canmore Rotary Club</H6>
                    <Body1>The Snowdog CAMBA purchased for grooming this year was funded with a grant from the Rotary Club of Canmore.</Body1>
                </Grid>
            </Grid>
            <Box mt={2}>
                <Grid container spacing={4}>
                    <Grid item className={c.like}>
                        <Body1>Like what we're doing and want to support us? Becoming a trail partner is a great way to support our winter grooming activities.</Body1>
                    </Grid>
                    <Grid item>
                        <Button href="https://camba.ca/partnership" variant="contained" color="primary">Learn More</Button>
                    </Grid>
                </Grid>
            </Box>
        </Section>
    )
}
