import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React, {useDispatch, useEffect, useGlobal, useState} from 'reactn';
import {formatDistance, parseISO} from 'date-fns';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Dialog from '../util/dialog';
import Section, {SectionHeading} from '../util/section';
import {Body1, CodeBlock} from '../util/text';
import AppPage from './page';


const InviteDialog = ({isOpen, handleClose}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const isReady = () => !!firstName.trim() && !!lastName.trim() && !!email.trim()
    && email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={isOpen}
      onClose={() => handleClose()}
    >
      <DialogTitle>Invite User</DialogTitle>
      <DialogContent>
        <Body1 gutter={4}>
          Record volunteer grooming shifts here.
        </Body1>
        <Grid container alignItems="center" justify="space-between" spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField label="First Name" variant="outlined" value={firstName} onChange={evt => setFirstName(evt.target.value)} fullWidth/>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="Last Name" variant="outlined" value={lastName} onChange={evt => setLastName(evt.target.value)} fullWidth/>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField label="Email Address" variant="outlined" value={email} onChange={evt => setEmail(evt.target.value)} fullWidth/>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose({
          first_name: firstName,
          last_name: lastName,
          email
        })} color="primary" variant="contained" disabled={!isReady()}>
          Invite
        </Button>
        <Button onClick={() => handleClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  head: {
    fontSize: theme.typography.subtitle2.fontSize,
    backgroundColor: theme.palette.grey["100"],
    textTransform: "uppercase",
    fontWeight: "bold",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  caption: {
    textTransform: "none",
    lineHeight: "0.75rem",
    fontSize: "0.75rem",
  },
  hours: {
    fontWeight: "bold",
    color: theme.palette.primary.main
  }
}))
const UsersPage = () => {
  const c = useStyles();
  const [api] = useGlobal("api");
  const [base] = useGlobal("base");
  const [users, setUsers] = useGlobal("users");
  const inviteUser = useDispatch("inviteUser");
  const [error, setError] = useState();
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    setError(null);
    if (users === null) {
      fetch(`${api}/users`)
        .then(rsp => rsp.json())
        .then(data => {
          setUsers(data.users)
        })
        .catch(setError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  const handleInvite = u => {
    try {
      if (u) {
        fetch(`${api}/invites`, {
          method: 'POST',
          body: JSON.stringify(u),
          headers: {'Content-Type': 'application/json'}
        })
          .then(rsp => {
            if (rsp.status === 200) {
              return rsp.json();
            }
            else throw Error(rsp.statusText)
          })
          .then(user => {
              inviteUser(user);
          })
          .catch(setError)
      }
    }
    finally {
      setShowDialog(false);
    }
  }

  return (
    <AppPage>
      <Section name="users">
        <SectionHeading title="App Users" icon={<AcUnitIcon/>}/>
        <Grid container justify="space-between" alignItems="center" spacing={1}>
          <Grid item xs={12} sm={6}>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => setShowDialog(true)}>Invite</Button>
          </Grid>
        </Grid>
        {error && <Box mt={1}>
          <Alert severity="error" variant="outlined">
            <AlertTitle>Oops, there was an error.</AlertTitle>
            <CodeBlock>
              {JSON.stringify(error)}
            </CodeBlock>
          </Alert>
        </Box>}
        <Box mt={1}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={c.head}>Name</TableCell>
                  <TableCell className={c.head}>Email</TableCell>
                  <TableCell className={c.head}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(users||[]).map(u => <TableRow key={u.id}>
                  <TableCell>
                    <Typography variant="body2">
                      {`${u.first_name} ${u.last_name}`}
                    </Typography>
                    <Typography variant="caption">
                      {u.invite && `Invited ${formatDistance(parseISO(u.invited), new Date())} ago`}
                      {u.login && `Logged in ${formatDistance(parseISO(u.login), new Date())} ago`}
                    </Typography>
                  </TableCell>
                  <TableCell>{u.email}</TableCell>
                  <TableCell>
                    {u.invite && <Tooltip title="Copy Invite Link">
                      <IconButton onClick={() => navigator.clipboard.writeText(`${base}/invite/${u.invite}`)}>
                        <FileCopyIcon/>
                      </IconButton>
                    </Tooltip>}
                  </TableCell>
                </TableRow>)}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {showDialog && <InviteDialog isOpen={showDialog} handleClose={handleInvite}/> }
      </Section>
    </AppPage>
  )
}

export default UsersPage;
