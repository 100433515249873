import React, {useGlobal} from 'reactn'
import {Link} from 'react-router-dom';
import makeStyles from "@material-ui/core/styles/makeStyles"
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import LaunchIcon from '@material-ui/icons/Launch';
import GroupIcon from '@material-ui/icons/Group';
import {WhiteLogo} from '../util/logo'
import {UserMenu} from '../util/user';


const useStyles = makeStyles(theme => ({
    brand: {
        color: theme.palette.primary.contrastText,
        "&:hover": {
            color: theme.palette.primary.contrastText,
            textDecoration: "none"
        },
        marginRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: "flex",
        flexGrow: 1,
        flexWrap: "wrap"
    },
    logo: {
        width: "8rem",
        [theme.breakpoints.up('sm')]: {
            width: "12rem"
        }
    },
    tag: {
        width: "100%",
        marginLeft: 0,
        [theme.breakpoints.up('sm')]: {
            width: "initial",
            marginLeft: theme.spacing(2)
        },
    },
    link: {
        color: "inherit",
        "&:hover": { color: "inherit" }
    }
}));

const Top = () => {
    const [user] = useGlobal("user");
    const c = useStyles();

    return (
        <AppBar position="static">
            <Toolbar>
                <Link to="/app" className={c.brand}>
                    <div className={c.logo}>
                        <WhiteLogo />
                    </div>
                    <Typography variant="h6" className={c.tag}>
                        Fat Bike Grooming
                    </Typography>
                </Link>
                {user && user.admin && <Link to="/app/users" className={c.link}>
                    <Tooltip title="Manage Users">
                        <IconButton color="inherit" size="small">
                            <GroupIcon />
                        </IconButton>
                    </Tooltip>
                </Link>}
                <Link to="/" className={c.link}>
                    <Tooltip title="Public Site">
                        <IconButton color="inherit" size="small">
                            <LaunchIcon />
                        </IconButton>
                    </Tooltip>
                </Link>
                <Box ml={2}>
                    <UserMenu/>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Top;
