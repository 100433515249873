import React from "reactn";
import PropTypes from 'prop-types';
import Avatar from "@material-ui/core/Avatar";
import green from '@material-ui/core/colors/green'
import yellow from '@material-ui/core/colors/yellow'
import red from '@material-ui/core/colors/red'
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ErrorOutlineOutlined from "@material-ui/icons/ErrorOutlineOutlined";
import CheckCircleOutlineOutlined from "@material-ui/icons/CheckCircleOutlineOutlined";



const groomShade = days => {
    if (days < 2) return "600";
    if (days < 4) return "500";
    if (days < 6) return "400";
    if (days < 8) return "300";
    if (days < 10) return "200";
    return "100";
    // if (days < 12) return "100";
    // return "50";
}

export const conditionPalette = condition => {
    switch(condition) {
        case "poor":
            return red;
        case "fair":
            return yellow;
        default:
            return green;
    }
}

export const segmentColor = (days, condition) => {
    return conditionPalette(condition)[groomShade(days)];
}

export const ConditionAvatar = ({condition, size, ...props}) => {
    const color = conditionPalette(condition)["600"];
    const Icon = condition === "poor" ? CancelOutlinedIcon :
        condition === "fair" ? ErrorOutlineOutlined : CheckCircleOutlineOutlined;
    return (
        <Avatar style={{color, backgroundColor: "white"}} {...props}>
            <Icon fontSize={size}/>
        </Avatar>
    )
}

ConditionAvatar.propTypes = {
    condition: PropTypes.string,
    size: PropTypes.string
}

ConditionAvatar.defaultProps = {
    size: "large"
}

export const ConditionLabel = ({condition, ...props}) => {
    const color = conditionPalette(condition)["600"];
    return (
        <span style={{color, textTransform: "uppercase"}} {...props}>
            {condition}
        </span>
    )
}

ConditionLabel.propTypes = {
    condition: PropTypes.string
}
