import React from 'reactn'
import {Link, useHistory} from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Section from '../util/section'
import {GreenLogo} from '../util/logo'
import pattern from '../images/patterns/topo-light.png'


const useStyles = makeStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.grey["200"]}`
    }
}))
const Top = () => {
    const c = useStyles();
    const history = useHistory();

    return (
        <div style={{backgroundImage: `url('${pattern}')`}} className={c.root}>
            <Section name="top" gutter={false}>
                <Grid container justify="space-between">
                    <Grid item xs={4} md={2}>
                        <Link to="/">
                            <GreenLogo />
                        </Link>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" startIcon={<ExitToAppIcon/>} onClick={() => history.push("/app")}>
                            Groomer Login
                        </Button>
                    </Grid>
                </Grid>
            </Section>
        </div>
    )
}
export default Top;
