
import React, {useDispatch, useEffect, useGlobal, useState} from 'reactn';
import {NumericFormat} from 'react-number-format';
import Pluralize from 'react-pluralize'
import {format, formatDistance, parseISO} from 'date-fns'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {Delete} from '@material-ui/icons';
import {DatePicker} from '@material-ui/pickers';
import Dialog from '../util/dialog';
import {useIsSmall} from '../util/media';
import Section, {SectionHeading} from '../util/section';
import {Body1, CodeBlock} from '../util/text';


const ShiftDialog = ({isOpen, handleClose}) => {
  const isSmall = useIsSmall();
  const [date, setDate] = useState(new Date());
  const [hours, setHours] = useState(1);
  const [note, setNote] = useState("");

  const setNow = () => {
    setDate(new Date());
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={isOpen}
      onClose={() => handleClose()}
    >
      <DialogTitle>Add Volunteer Hours</DialogTitle>
      <DialogContent>
        <Body1 gutter={4}>
          Record volunteer grooming shifts here.
        </Body1>
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <FormHelperText>
              Date
            </FormHelperText>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={setNow}>Set to Today</Button>
          </Grid>
        </Grid>
        <Grid container spacing={isSmall?0:1}>
          <Grid item xs={12}>
            <DatePicker
              autoOk
              // orientation="landscape"
              variant="static"
              value={date}
              onChange={setDate}
            />
          </Grid>
        </Grid>
        <Box mt={1}>
          <NumericFormat value={hours} customInput={TextField} label="Hours" variant="outlined"
            onValueChange={v => setHours(v.floatValue)}/>
        </Box>
        <Box mt={1}>
          <TextField
            label="Notes"
            variant="outlined"
            rows={4}
            value={note}
            onChange={evt => setNote(evt.target.value)}
            multiline
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose({
          date: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
          hours,
          note
        })} color="primary" variant="contained">
          Add
        </Button>
        <Button onClick={() => handleClose()} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const useHoursTableStyles = makeStyles(theme => ({
  head: {
    fontSize: theme.typography.subtitle2.fontSize,
    backgroundColor: theme.palette.grey["100"],
    textTransform: "uppercase",
    fontWeight: "bold",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  caption: {
    textTransform: "none",
    lineHeight: "0.75rem",
    fontSize: "0.75rem",
  },
  hours: {
    fontWeight: "bold",
    color: theme.palette.primary.main
  }
}))
const HoursTable = ({hours, handleDelete}) => {
  const classes = useHoursTableStyles();
  return (
    <Paper>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.head}>Date</TableCell>
              <TableCell className={classes.head}>Hours</TableCell>
              <TableCell className={classes.head}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(hours.shifts||[]).map(s => <TableRow key={s.id}>
              <TableCell>
                <Typography variant="body2">{format(parseISO(s.date), 'MMM dd, yyyy')}</Typography>
                <Typography variant="caption">{`${formatDistance(parseISO(s.date), new Date())} ago`}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" className={classes.hours}>{s.hours}</Typography>
                {s.note && <Typography variant="caption">{s.note}</Typography>}
              </TableCell>
              <TableCell>
                <Tooltip title="Delete Shift">
                  <IconButton size="small" onClick={() => handleDelete(s)}>
                    <Delete/>
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
        <Box px={2} py={1} className={classes.head}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="caption">
                <Pluralize singular={'shift'} count={hours.total_shifts}/>
              </Typography>
              <Box>
                <Typography variant="caption" className={classes.caption}>
                  Last 5 shown above.
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                <Pluralize singular={'hour'} count={hours.total}/>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </TableContainer>
    </Paper>
  )
}
export const HoursPanel = () => {
  const [api] = useGlobal("api");
  const [hours, setHours] = useGlobal("hours");
  const [showDialog, setShowDialog] = useState(false);
  const [error, setError] = useState();
  const addHours = useDispatch("addHours");
  const removeHours = useDispatch("removeHours");

  useEffect(() => {
    if (api && setHours && hours === null) {
      fetch(`${api}/hours`)
        .then(result => result.json())
        .then(setHours);
    }
  }, [hours, setHours, api])

  const addShift = (s) => {
    try {
      if (s) {
        fetch(`${api}/hours`, {
          method: "POST",
          body: JSON.stringify(s),
          headers: {
            'Content-Type': "application/json"
          }
        })
          .then(result => {
            if (result.headers.get("Content-Type") !== 'application/json') {
              setError("An unknown error occurred");
            }
            else return result.json()
          })
          .then(data => {
            if ("error" in data) {
              setError(data);
            }
            else {
              addHours(s);
            }
          })
      }
    }
    finally {
      setShowDialog(false);
    }
  }

  const removeShift = (s) => {
    setError(null);
    fetch(`${api}/hours/${s.id}`, {
      method: "DELETE"
    })
      .then(result => {
        if (result.headers.get("Content-Type") !== 'application/json') {
          setError("An unknown error occurred");
        }
        else return result.json()
      })
      .then(data => {
        if ("error" in data) {
          setError(data);
        }
        else {
          removeHours(s);
        }
      })
  }

  return (
    <Section name="hours">
      <SectionHeading title="Hours" icon={<WatchLaterOutlinedIcon/>}/>
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item xs={12} sm={6}>
          <Body1>Record your grooming shifts and volunteer hours.</Body1>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => setShowDialog(true)}>Add</Button>
        </Grid>
      </Grid>
      {hours && hours.shifts.length > 0 && <Box mt={1}>
        <HoursTable hours={hours} handleDelete={removeShift}/>
      </Box>}
      {error && <Box mt={1}>
        <Alert severity="error" variant="outlined">
          <AlertTitle>Oops, there was an error.</AlertTitle>
          <CodeBlock>
            {JSON.stringify(error)}
          </CodeBlock>
        </Alert>
      </Box>}
      {showDialog && <ShiftDialog isOpen={showDialog} handleClose={addShift}/>}
    </Section>
  )
}
