import {setGlobal, addReducer} from 'reactn'
import {FB_TOKEN_KEY} from './index'

const initState = () => {
    const empty = {
        user: null,
        totals: null,
        trails: [],
        recent: [],
        region: null,
        regions: [],
        advisories: [],
        hours: null,
        error: null,
        segment: null,
        users: null
    }

    setGlobal({
        base: window.location.hostname === "localhost" ? "http://localhost:3001" : "https://fatbike.camba.ca",
        api: window.location.hostname === "localhost" ? "http://localhost:5001" : "https://camba-fatbike.herokuapp.com",
        ...empty
    });

    addReducer("chooseSegment", (global, dispatch, segment) => ({
        segment
    }));

    addReducer("updateSegment", (global, dispatch, segment, update) => ({
        trails: global.trails.map(t => t.id === segment.trail_id ? {
            ...t,
            segments: t.segments.map(s => s.id === segment.id ? {
                ...s,
                groomed: (update.groomed || new Date()).toISOString(),
                condition: update.condition,
                note: update.note
            }:s)
        }:t)
    }));
    addReducer("updateTrail", (global, dispatch, trail, update) => ({
        trails: global.trails.map(t => t.id === trail.id ? {
            ...t,
            segments: t.segments.map(s => ({
                ...s,
                groomed: (update.groomed || new Date()).toISOString(),
                condition: update.condition,
                note: update.note
            }))
        }:t)
    }));
    addReducer("postAdvisory", (global, dispatch, region, message, date) => ({
        region: {
            ...global.region,
            advisory: message,
            advisory_date: date.toISOString(),
            advisory_author: global.user
        },
        regions: global.regions.map(r => r.id === region.id ? {
            ...r,
            advisory: message,
            advisory_date: date.toISOString(),
            advisory_author: global.user
        }:r)
    }));

    addReducer("clearAdvisory", (global, dispatch, region) => ({
        region: {
            ...global.region,
            advisory: null,
            advisory_date: null,
            advisory_author: null
        },
        regions: global.regions.map(r => r.id === region.id ? {
            ...r,
            advisory: null,
            advisory_date: null,
            advisory_author: null
        }:r)
    }));

    addReducer("changeRegion", (global, dispatch, id) => ({
        region: global.regions.find(r => r.id === id),
        trails: []
    }));

    addReducer("addHours", (global, dispatch, shift) => ({
        hours: {
            shifts: [...((global.hours||{}).shifts||[]), {...shift, date: shift.date.toISOString()}],
            total_shifts: ((global.hours||{}).total_shifts||0)+1,
            total: ((global.hours||{}).total||0) + shift.hours
        }
    }));

    addReducer("removeHours", (global, dispatch, shift) => ({
        hours: {
            shifts: ((global.hours||{}).shifts||[]).filter(it => it.id !== shift.id),
            total_shifts: ((global.hours||{}).total_shifts||0)-1,
            total: Math.max(0,((global.hours||{})||0).total - shift.hours)
        }
    }));

    addReducer("login", (global, dispatch, user) => ({
      user: {
          ...user,
          admin: ((user.roles||{})["*"]||[]).includes("admin")
      }
    }));

    addReducer("logout", () => {
        localStorage.removeItem(FB_TOKEN_KEY);
        return {
            ...empty
        };
    });
    addReducer("inviteUser", (global, dispatch, user) => ({
       users: [user, ...global.users]
    }));
}
export default initState;
