import React, {useState, useGlobal, useDispatch} from "reactn";
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';
import {SM} from "./text";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";


const useStyles = makeStyles(theme => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        fontSize: SM
    },
    default: {
        width: theme.spacing(5),
        height: theme.spacing(5)
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}))
export const UserAvatar = ({user, size, ...props}) => {
    const c = useStyles();

    return (
        <Avatar {...props} className={c[size]} >
            {`${user.first_name.charAt(0)}${user.last_name.charAt(0)}`}
        </Avatar>
    )
}

UserAvatar.propTypes = {
    user: PropTypes.object,
    size: PropTypes.string
}

UserAvatar.defaultProps = {
    size: "default"
}

export const UserMenu = () => {
    const [anchor, setAnchor] = useState(null);
    const [user,] = useGlobal("user");
    const logout = useDispatch("logout");

    const handleLogout = () => {
        setAnchor(null);
        logout();
    }

    return (
        <>
            {user && <UserAvatar user={user} onClick={evt => setAnchor(evt.currentTarget)} />}
            <Menu
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={() => setAnchor(null)}
            >
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Logout</Typography>
                </MenuItem>
            </Menu>
        </>
    )
}
