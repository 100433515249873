import React, {useGlobal, useEffect, useState} from 'reactn'
import {useParams} from 'react-router-dom'
import makeStyles from "@material-ui/core/styles/makeStyles";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import StarIcon from '@material-ui/icons/Star';
import Top from './top'
import SEO from "../util/seo";
import Spinner from "../util/spinner";
import Section, {SectionHeading} from "../util/section";
import {Body1} from "../util/text";

const useStyles = makeStyles(() => ({
    form: {
        maxWidth: 728,
        margin: "auto"
    },
    alert: {
        width: "100%"
    }
}))
const InvitePage = () => {
    const [api] = useGlobal("api");
    const [isLoading, setLoading] = useState(true);
    const [isWorking, setWorking] = useState(false);
    const [bad, setBad] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false);
    const { invite } = useParams();
    const [info, setInfo] = useState({
        email: "",
        first_name: "",
        last_name: "",
        passwd: "",
        confirm: ""
    });
    const c = useStyles();

    useEffect(() => {
        if (invite && api) {
            fetch(`${api}/invites/${invite}`)
                .then(rsp => {
                    if (rsp.status !== 200) {
                        throw Error(rsp.status);
                    }
                    return rsp;
                })
                .then(rsp => rsp.json())
                .then(data => {
                    setInfo({...info, ...data});
                    setLoading(false);
                })
                .catch(() => {
                    setBad(true);
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = evt => {
        setInfo({...info, [evt.target.name]: evt.target.value})
    }

    const finish = () => {
        setWorking(true);
        setError(false);
        fetch(`${api}/invites/${invite}`, {
            method: "PUT",
            body: JSON.stringify(info),
            headers: {"Content-Type": "application/json"}
        })
        .then(rsp => {
            if (rsp.status !== 200) {
                throw Error(rsp.status);
            }
            return rsp;
        })
        .then(rsp => rsp.json())
        .then(() => {
            setLoading(false);
            setSuccess(true);
        })
        .catch(() => {
            setError(true);
            setLoading(false);
        });
    }

    // eslint-disable-next-line eqeqeq
    const isInvalid = () => !!info.passwd && (info.passwd != info.confirm)
    const trim = v => v.trim()
    const isDone = () => trim(info.first_name) && trim(info.last_name) && trim(info.passwd) && trim(info.confirm) && !isInvalid();

    return (
        <>
            <SEO title="Fat Bike Grooming"/>
            <Top/>
            <Section name="invite">
                {isLoading && <Spinner />}
                {!isLoading && bad && <Alert severity="error">
                    <AlertTitle>Invalid Invite</AlertTitle>
                    Sorry, this invite is no longer valid or has expired.
                </Alert>}
                {!isLoading && !bad && <>
                    <SectionHeading title="Welcome" icon={<StarIcon/>}/>
                    <Body1>
                        Congrats! You've been invited to join the CAMBA fat bike grooming and conditions app. We just need you to set a
                        password to finalize your account.
                    </Body1>
                    {error && <Box my={4}><Alert severity="error">
                        <AlertTitle>Whoops, an error occurred.</AlertTitle>
                        Looks like an error occurred finalizing your account, sorry about that. Please let us know by emailing us
                        as <a href={`mailto:contact@camba.ca?subject=Error accepting invite ${invite}`}>contact@camba.ca</a>.
                    </Alert></Box>}
                    <form className={c.form}>
                        <Box mt={8}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        value={info.email}
                                        label="Email"
                                        name="email"
                                        type="email"
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        value={info.first_name} onChange={handleChange}
                                        label="First name"
                                        name="first_name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        value={info.last_name} onChange={handleChange}
                                        label="Last name"
                                        name="last_name"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        value={info.passwd} onChange={handleChange}
                                        label="Password"
                                        name="passwd"
                                        variant="outlined"
                                        type="password"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <TextField
                                        value={info.confirm} onChange={handleChange}
                                        label="Confirm"
                                        name="confirm"
                                        variant="outlined"
                                        type="password"
                                        fullWidth
                                        error={isInvalid()}
                                        helperText={isInvalid()?"Passwords do not match":null}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={4} display="flex" justifyContent="center">
                            {success ? <Alert className={c.alert} action={<Button color="inherit" size="small" href="/app">
                                    Log in
                                </Button>}>
                                <AlertTitle>Success!</AlertTitle>
                                Your account is all ready to go. You can now log in into the app.
                            </Alert> :
                            <Button variant="contained" color="primary" disabled={!isDone() || isWorking} onClick={finish}
                                    startIcon={isWorking ? <CircularProgress size={24}/> : <ExitToAppIcon/>}
                            >
                                Finish
                            </Button>
                            }
                        </Box>
                    </form>

                </>}
            </Section>
        </>
    );
}

export default InvitePage;
