import React, {useEffect, useGlobal, useState} from "reactn";
import {scroller} from "react-scroll";
import {useHistory} from "react-router-dom";
import SvgIcon from "@material-ui/core/SvgIcon";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Section, {SectionHeading} from "../util/section";
import {Body1, CodeBlock} from "../util/text";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import SEO from "../util/seo";
import Spinner from "../util/spinner";
import Top from "./top";
import {ReactComponent as ConnectStravaIcon} from "../images/logos/strava-connect.svg"

const StravaIcon = () => {
    return (<SvgIcon viewBox="0 0 384 512" style={{fontSize: "3.5rem"}}>
        <path fill="currentColor"
              d="M158.4 0L7 292h89.2l62.2-116.1L220.1 292h88.5zm150.2 292l-43.9 88.2-44.6-88.2h-67.6l112.2 220 111.5-220z"></path>
    </SvgIcon>)
}

export const StravaPanel = () => {
    const [api] = useGlobal("api");
    const [user,setUser] = useGlobal("user");
    const [error, setError] = useState();

    const connect = () => {
        const callback = `${window.location.origin}/app/strava`
        window.location.replace(
            `https://www.strava.com/oauth/authorize?client_id=75898&redirect_uri=${callback}&response_type=code&approval_prompt=auto&scope=activity:read,activity:read_all`
        )
    }

    const disconnect = () => {
        setError(null);
        fetch(`${api}/strava`, {
            method: "DELETE"
        })
            .then(result => {
                if (result.headers.get("Content-Type") !== 'application/json') {
                    setError("An unknown error occurred");
                }
                else return result.json()
            })
            .then(data => {
                if ("error" in data) {
                    setError(data);
                }
                else {
                    setUser(data);
                }
            })

    }

    return (
        <Section name="strava">
            <SectionHeading title="Strava" icon={<StravaIcon/>}/>
            <Grid container justify="space-between" alignItems="center" spacing={1}>
                <Grid item>
                    {user.strava_id && <Body1>Strava is connected.</Body1>}
                    {!user.strava_id && <Body1>Connect to your Strava to automatically update grooming conditions.</Body1>}
                </Grid>
                <Grid item>
                    {user.strava_id && <Button variant="contained" color="primary" onClick={disconnect}>Disconnect</Button>}
                    {!user.strava_id && <Button onClick={connect}>
                        <ConnectStravaIcon/>
                    </Button>}
                </Grid>
            </Grid>
            {error && <Box mt={1}>
                <Alert severity="error" variant="outlined">
                    <AlertTitle>Oops, there was an error.</AlertTitle>
                    <CodeBlock>
                        {JSON.stringify(error)}
                    </CodeBlock>
                </Alert>
            </Box>}

        </Section>
    )
}

export const StravaPage = () => {
    const [api] = useGlobal("api");
    const [,setUser] = useGlobal("user");
    const [isConnecting, setConnecting] = useState(false);
    const [error, setError] = useState();
    const history = useHistory();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        if (params.has('code')) {
            const perms = (params.get("scope") || "").split(/\s*,\s*/);
            if (perms.includes("activity:read")) {
                setConnecting(true);
                scroller.scrollTo('strava', {
                    duration: 500,
                    smooth: true
                });

                fetch(`${api}/strava`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({code: params.get('code'), scope: params.get('scope')}),
                })
                    .then(rsp => {
                        return rsp.json()
                    })
                    .then(data => {
                        if ("error" in data) {
                            setError(data);
                            setConnecting(false);
                        }
                        else {
                            setUser(data).then(() => setConnecting(false))
                        }
                    })
                    .catch(err => {
                        setError(err)
                        setConnecting(false)
                    })
            } /*else {
                // setPerms(false);
                setConnecting(false)
            }*/
        }
        else {
            setError("No code specified");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <SEO title="Fat Bike Grooming | Strava"/>
            <Top/>
            <Section name="strava">
                <SectionHeading title="Strava Connection" icon={<StravaIcon/>}/>
                {isConnecting && <Spinner/>}
                {error &&
                <Alert severity="error" variant="outlined">
                    <AlertTitle>Oops, there was an error.</AlertTitle>
                    <CodeBlock>
                        {JSON.stringify(error)}
                    </CodeBlock>
                </Alert>}
                <Box mt={4} display="flex" justifyContent="center">
                    <Button variant="outlined" color="primary" onClick={() => history.replace("/app")}>Return to Grooming App</Button>
                </Box>
            </Section>
        </>
    )
}
