import React from 'reactn';
import Advisory from './advisory';
import {HoursPanel} from './hours';
import AppPage from './page';
import Region from './region';
import {StravaPanel} from './strava';
import Trails from './trails';

const DashPage = () => {
  return (
    <AppPage>
      <Region/>
      <Trails/>
      <Advisory/>
      <StravaPanel/>
      <HoursPanel/>
    </AppPage>
  )
}

export default DashPage;
