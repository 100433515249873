import React from 'reactn'
import useTheme from '@material-ui/core/styles/useTheme'
import {ReactComponent as CambaWordLogo} from '../images/logos/camba-word.svg'

export function Logo() {
    return (
        <CambaWordLogo style={{fill: 'currentColor'}}/>
    )
}

export function GreenLogo({...props}) {
    const theme = useTheme()
    return (
        <span style={{color: theme.palette.primary.main}} {...props}>
      <Logo />
    </span>
    )
}

export function WhiteLogo() {
    const theme = useTheme();
    return (
        <span style={{color: theme.palette.common.white}}>
      <Logo />
    </span>
    )
}
