import React, {useDispatch, useGlobal} from 'reactn'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Section from '../util/section'
import {useRegions} from "../util/useRegions";


const useStyles = makeStyles(theme => ({
    select: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}))
const Region = () => {
    const c = useStyles();
    const [region, ] = useGlobal("region");
    // const [regions, setRegions] = useGlobal("regions");
    const regions = useRegions();
    const changeRegion = useDispatch("changeRegion");

    const handleChange = evt => {
        console.log(evt.target.value);
        changeRegion(evt.target.value);
    }
    return (
        region ?
            <Section name="region">
                {region &&
                <FormControl variant="outlined" className={c.select}>
                    <InputLabel>Region</InputLabel>
                    <Select
                        value={region.id}
                        onChange={handleChange}
                        label="Region"
                    >
                        {regions.map(r => <MenuItem key={r.id} value={r.id}>{r.name}</MenuItem>)}
                    </Select>
                </FormControl>}
            </Section> : null
    )
}

export default Region;
