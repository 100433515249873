import React, {useEffect, useGlobal, useDispatch, useState} from 'reactn'
import PropTypes from 'prop-types'
import {formatDistance, parseISO, format} from 'date-fns';
import withStyles from '@material-ui/core/styles/withStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Alert from '@material-ui/lab/Alert'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import AcUnitIcon from '@material-ui/icons/AcUnit'
import NotesIcon from '@material-ui/icons/Notes'
import AlertTitle from '@material-ui/lab/AlertTitle'
import {isSomething} from '../util/functions'
import {useIsSmall} from '../util/media'
import Section, {SectionHeading} from '../util/section'
import {Body2, CodeBlock, H6, Subtitle1, Subtitle2} from '../util/text'
import UpdateDialog from './update'
import {ConditionAvatar} from "../util/condition";
import {UserAvatar} from "../util/user";


const Cell = withStyles(() => ({
    root: {
        paddingLeft: 0
    }
}))(TableCell);

const useTrailRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    expander: {
        textAlign: "right"
    }
});
const TrailRow = ({trail, apiUrl}) => {
    const [open, setOpen] = React.useState(false);
    const c = useTrailRowStyles();

    return (
        <>
            <TableRow className={c.root}>
                <TableCell component="th" scope="row">
                    <H6>{trail.name}</H6>
                    <Subtitle1>{trail.region}</Subtitle1>
                </TableCell>
                {trail.segments.length === 1 && <TableCell/>}
                {trail.segments.length > 1 &&
                <TableCell className={c.expander}>
                    <IconButton aria-label='expand row' onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>}
            </TableRow>
            {trail.segments.length > 1 &&
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={0}>
                            {/*<Body2 className={c.segments}>Segments</Body2>*/}
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <Cell>Segment</Cell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {trail.segments.map(s => <SegmentRow key={s.id} segment={s} apiUrl={apiUrl}/>)}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>}
        </>
    );
}
TrailRow.propTypes = {
    trail: PropTypes.object,
    apiUrl: PropTypes.string
}

const UpdateActions = ({segment, onUpdate}) => {
    const [open, setOpen] = useState(false);
    const isSmall = useIsSmall();
    const {condition, groomed, note} = segment;

    const handleUpdate = u => {
        setOpen(false);
        if (u) onUpdate(u)
    }

    return (
        <>
            <Tooltip title="Quick Update">
                <IconButton onClick={() => onUpdate({condition: "good", groomed: new Date(), note: "" })}
                            size={isSmall?"small":"medium"}>
                    <CheckCircleOutlineIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Mark as groomed (advanced)">
                <IconButton size={isSmall?"small":"medium"} onClick={() => setOpen(true)}>
                    <NotesIcon/>
                </IconButton>
            </Tooltip>
            <UpdateDialog open={open} groomed={parseISO(groomed)} handleClose={handleUpdate} {...{condition, note}}/>
        </>
    )
}
UpdateActions.propTypes = {
    segment: PropTypes.object,
    onUpdate: PropTypes.func
}

const GroomInfo = ({segment}) => {
    const groomed = isSomething(segment.groomed) && parseISO(segment.groomed);
    return (
        groomed ? <>
            <Subtitle1>Groomed {formatDistance(groomed, new Date())} ago</Subtitle1>
            <Subtitle2>{format(groomed, "EEE, LLL do 'at' HH:mm")}</Subtitle2>
        </> : null
    )
}
GroomInfo.propTypes = {
    segment: PropTypes.object
}

const AuthorInfo = ({segment}) => {
    const isSmall = useIsSmall();
    const updated = parseISO(segment.updated);
    const name = `${segment.author.first_name} ${segment.author.last_name}`
    return (
        <Tooltip title={`Updated by ${name} ${formatDistance(updated, new Date())} ago on ${format(updated, "EEE, LLL do 'at' HH:mm")}`}>
            <IconButton onClick={() => console.log()}
                        size={isSmall?"small":"medium"}>
                <UserAvatar user={segment.author} size="small"/>
            </IconButton>
        </Tooltip>
    )
}
AuthorInfo.propTypes = {
    segment: PropTypes.object
}
const useSegmentRowStyles = makeStyles(theme => ({
    root: {
      borderTop: `1px solid ${theme.palette.grey["200"]}`,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    name: {
        flexGrow: 1
    },
    right: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "space-between"
    }
}))
const SegmentRow = ({segment, apiUrl, ...props}) => {
    const c = useSegmentRowStyles();
    const updateSegment = useDispatch("updateSegment");
    const [,setError] = useGlobal("error");

    const handleUpdate = u => {
        fetch(`${apiUrl}/segments/${segment.id}/updates`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(u)
        }).then(() => {
            updateSegment(segment, u)
        }).catch(setError)
    }
    return (
        <Grid container {...props} alignItems="flex-start" className={c.root}>
            <Grid item className={c.name}>
                <Box display="flex" style={{alignItems: "center"}}>
                    <ConditionAvatar condition={segment.condition} size={"default"}/>
                    <Body2>{segment.name}</Body2>
                </Box>
                <GroomInfo segment={segment}/>
            </Grid>
            <Grid item className={c.right}>
                <Box>
                    <UpdateActions segment={segment} onUpdate={handleUpdate} />
                </Box>
                {segment.updated && <AuthorInfo segment={segment}/>}
            </Grid>
        </Grid>
    )
}
SegmentRow.propTypes = {
    segment: PropTypes.object,
    apiUrl: PropTypes.string
}
const useTrailPanelStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    name: {
        // flexGrow: 1,
        flex: "1 0 auto",
        paddingBottom: theme.spacing(1)
    },
    right: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "space-between"
    }
}))
const TrailPanel = ({trail, apiUrl, ...props}) => {
    const c = useTrailPanelStyles();
    const updateTrail = useDispatch("updateTrail");
    const [, setError] = useGlobal("error");
    const [open, setOpen] = useState(false);

    const handleUpdate = u => {
        fetch(`${apiUrl}/trails/${trail.id}/updates`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(u)
        }).then(() => {
            updateTrail(trail, u);
        }).catch(setError)
    }

    const isSingle = trail.segments.length === 1;
    const firstSegment = trail.segments.find(() => true);
    return (
        <Paper className={c.root} {...props}>
            <Grid container alignItems="flex-start" wrap={"nowrap"}>
                <Grid item className={c.name}>
                    <Box display="flex" style={{alignItems: "center"}}>
                        {isSingle && <ConditionAvatar condition={firstSegment.condition} size="default"/> }
                        <H6>{trail.name}</H6>
                    </Box>
                    {isSingle && <GroomInfo segment={firstSegment}/> }
                    {/*<Subtitle1>{trail.region}</Subtitle1>*/}
                </Grid>
                {trail.segments.length > 1 &&
                <Grid item>
                    <Tooltip title="Show individual segments">
                        <IconButton aria-label='expand row' onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Tooltip>
                </Grid>
                }
                <Grid item className={c.right}>
                    <Box>
                        <UpdateActions onUpdate={handleUpdate} segment={firstSegment}/>
                    </Box>
                    {isSingle && firstSegment.updated && <AuthorInfo segment={firstSegment}/>}
                </Grid>
            </Grid>
            {open && trail.segments.map(s => <SegmentRow segment={s} apiUrl={apiUrl} key={s.id}/>)}
        </Paper>
    )
}
TrailPanel.propTypes = {
    trail: PropTypes.object,
    apiUrl: PropTypes.string
}
const Trails = () => {
    const [api] = useGlobal("api");
    const [region] = useGlobal("region");
    const [trails, setTrails] = useGlobal("trails");
    const [error, setError] = useGlobal("error");

    useEffect(() => {
        if (trails.length === 0 && region && api && setTrails) {
            fetch(`${api}/trails/${region.id}?sort=name&order=asc`)
                .then(rsp => rsp.json())
                .then(data => setTrails(data.trails))
                .catch(err => {
                    setError(err);
                    console.log(err)
                });
        }

    }, [api, trails, region, setTrails, setError])

    return (
        <Section name="trails">
            <SectionHeading title="Trails" icon={<AcUnitIcon/>}/>
            {error &&
            <Alert severity="error" variant="outlined">
                <AlertTitle>Oops, there was an error.</AlertTitle>
                <CodeBlock>
                    {JSON.stringify(error)}
                </CodeBlock>
            </Alert>}
            {(trails||[]).map(t => <TrailPanel key={t.name} trail={t} apiUrl={api}/>)}
        </Section>
    )
}
export default Trails;
