import React, {useGlobal, useRef, useState} from "reactn";
import {scroller, Element} from "react-scroll";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Alert from "@material-ui/lab/Alert";
import Section from "../util/section";
import {Body1, Body2, H3, H6} from "../util/text";
import {useRegions} from "../util/useRegions";
import {STYLE_DARK, STYLE_LIGHT, TrailMap} from "../home/map";
import {TrailTable} from "../home/trails";
import MapImage from "../images/widgets/map.jpg";
import TableImage from "../images/widgets/table.jpg";


const WIDGET_TYPE_MAP = "map";
const WIDGET_TYPE_TABLE = "table";

const useStepHeadingStyles = makeStyles(theme => ({
    number: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "100%",
        fontSize: "24pt",
        fontWeight: 900,
        padding: theme.spacing(2),
        marginRight: theme.spacing(1),
        border: `4px solid ${theme.palette.primary.main}`,
        width: theme.spacing(4),
        height: theme.spacing(4),
    }
}));
const StepHeading = ({number, title}) => {
    const c = useStepHeadingStyles();
    return (
        <Box display="flex" alignItems="center">
            <span className={c.number}>{number}</span>
            <H3>{title}</H3>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    image: {
        width: "128px",
        height: "auto",
        borderWidth: 2,
        borderColor: theme.palette.grey["300"],
        borderStyle: "solid",
        borderRadius: "2px",
        // filter: "brightness(50%)",
        // "&:hover": {
        //     cursor: "pointer",
        //     borderColor: theme.palette.primary.main,
        //     // filter: "brightness(85%)",
        // }
    },
    selected: {
        filter: "none",
        borderWidth: "2px",
        borderColor: theme.palette.primary.main
    },
    button: {
        alignSelf: "flex-start"
    },
    codeblock: {
        backgroundColor: theme.palette.grey["100"],
        border: `1px solid ${theme.palette.grey["300"]}`,
        margin: 0,
        padding: theme.spacing(2),
        whiteSpace: "pre-wrap"
    },
    iframe: {
        border: `1px solid ${theme.palette.grey["300"]}`
    }
}))
const Builder = () => {
    const c = useStyles();
    const [base] = useGlobal("base");
    const regions = useRegions();
    const codeRef = useRef();

    const scrollTo = name => {
        setTimeout(() => {
            scroller.scrollTo(name, {
                duration: 500,
                smooth: true
            })
        }, 250);
    }

    const [type, setType] = useState();
    const chooseType = t => {
        setType(t);
        scrollTo('config');
    }

    const [config, setConfig] = useState({
        withRegions: true,
        defaultRegion: "",
        rows: 10,
        style: STYLE_DARK
    });
    const updateConfig = (key, converter) => evt => {
        let v = typeof evt.target != "undefined" ? evt.target.value : evt;
        if (converter) v = converter(v);

        setConfig({
            ...config,
            [key]: v
        })
    }

    const url = () => {
        const p = {
            s: config.style === STYLE_DARK ? "dark" : "light",
            n: config.rows
        }
        if (config.defaultRegion) {
            p.r = config.defaultRegion;
        }

        const q = Object.keys(p).map(k => `${k}=${p[k]}`).join("&");
        return `${base}/widgets/${type}?${q}`
    }

    const [done, setDone] = useState(false);
    const finishConfig = () => {
        setDone(true);
        scrollTo('code');
    }

    const [size, setSize] = useState({
        width: "512",
        height: "512"
    });
    const updateSize = key => evt => {
        setSize({
            ...size,
            [key]: evt.target.value
        })
    }

    const [copied, setCopied] = useState(false);
    const copyCode = () => {
        // console.log(codeRef.current);
        if (codeRef.current) {
            const code = codeRef.current.innerHTML;
            if (code) {
                const c = code.replaceAll("&lt;", "<")
                    .replaceAll("&gt;", ">")
                    .replaceAll("&amp;", "&");
                navigator.clipboard.writeText(c)
                    .then(() => setCopied(true));
            }
        }
    }
    return (
        <>
            <Section name="type">
                <StepHeading number={1} title={"Choose the type of Widget"}/>
                <Box mt={4}>
                    <Grid container justify="space-evenly" spacing={4}>
                       <Grid item md={6}>
                           <Box display="flex">
                               <img src={MapImage} alt="Map Widget" className={clsx(c.image, {
                                   [c.selected]: type === WIDGET_TYPE_MAP
                               })}/>
                               <Box pl={2} display="flex" flexDirection="column" justifyContent="space-between">
                                   <div>
                                       <H6>Trail Map</H6>
                                       <Body1>An interactive map displaying grooming status using a color coded system.</Body1>
                                   </div>
                                   <Button variant="outlined" color="primary" size="small" className={c.button}
                                           onClick={() => chooseType(WIDGET_TYPE_MAP)}>Choose</Button>
                               </Box>
                           </Box>

                       </Grid>
                        <Grid item md={6}>
                            <Box display="flex">
                                <img src={TableImage} alt="Trail Table" className={clsx(c.image, {
                                    [c.selected]: type === WIDGET_TYPE_TABLE
                                })}/>
                                <Box pl={2} display="flex" flexDirection="column" justifyContent="space-between">
                                    <div>
                                        <H6>Trail Table</H6>
                                        <Body1>A sortable list displaying grooming status in a tabular format.</Body1>
                                    </div>
                                    <Button variant="outlined" color="primary" size="small" className={c.button}
                                            onClick={() => chooseType(WIDGET_TYPE_TABLE)}>Choose</Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Section>
            {type && <Section name="config">
                <StepHeading number={2} title={"Configure the Widget"}/>
                <Box mt={4}>
                    <Grid container justify="space-evenly" spacing={4}>
                        <Grid item md={6}>
                            {type === WIDGET_TYPE_MAP && <div style={{height: "512px"}}>
                                <TrailMap {...config} setStyle={s => updateConfig("style")(s)} withRegions={!config.defaultRegion} withLogo={true}/>
                            </div>}
                            {type === WIDGET_TYPE_TABLE && <div style={{height: "512px", overflow: "scroll"}}>
                                <TrailTable {...config} withRegions={!config.defaultRegion} withLogo/>
                            </div>}
                        </Grid>
                        <Grid item md={6}>
                            <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start" height="100%">
                                <Box>
                                    <Box>
                                        <H6>Region</H6>
                                        <Body2 gutter={1}>Limit the widget to trails in a specific region, or show data from all regions.</Body2>
                                        <Select value={config.defaultRegion} onChange={updateConfig("defaultRegion")} variant="outlined" displayEmpty>
                                            <MenuItem value="">
                                                <em>Display All Regions</em>
                                            </MenuItem>
                                            {regions.map(r => <MenuItem key={r.id} value={r.short_name}>
                                                {r.name}
                                            </MenuItem>)}
                                        </Select>
                                    </Box>
                                    {type === WIDGET_TYPE_MAP && <Box mt={2}>
                                        <H6>Map Style</H6>
                                        <Body2 gutter={1}>Choose between a light and dark version of the map.</Body2>
                                        <Select value={config.style} onChange={updateConfig("style")} variant="outlined" displayEmpty>
                                            <MenuItem value={STYLE_LIGHT}>
                                                Light
                                            </MenuItem>
                                            <MenuItem value={STYLE_DARK}>
                                                Dark
                                            </MenuItem>
                                        </Select>
                                    </Box>}
                                    {type === WIDGET_TYPE_TABLE && <Box mt={2}>
                                        <H6>Table Rows</H6>
                                        <Body2 gutter={1}>Choose the number of rows to display in the table.</Body2>
                                        <TextField value={config.rows} onChange={updateConfig("rows", parseInt)} variant="outlined"/>
                                    </Box>}
                                </Box>
                                <Button variant="outlined" color="primary" size="small" onClick={finishConfig}>Next</Button>
                            </Box>

                        </Grid>
                    </Grid>
                </Box>
            </Section>}
            {done && <Section name="code">
                <StepHeading number={3} title={"Generate the Code"}/>
                <Box mt={4}>
                    <Grid container justify="space-evenly" spacing={4}>
                        <Grid item md={6}>
                            <Box mb={2}>
                                <pre className={c.codeblock} ref={codeRef}>{`
<iframe src="${url()}" width="${size.width}" height="${size.height}"/>
`}                              </pre>
                            </Box>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Button variant="outlined" color="primary" size="small" onClick={() => copyCode()}>Copy</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6}>
                            <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="flex-start" height="100%">
                                <Box>
                                    <H6>Size</H6>
                                    <Body2 gutter={1}>Configure the width and height of the widget.</Body2>
                                    <Box my={2}>
                                        <Grid container spacing={2}>
                                            <Grid item md={6}>
                                                <TextField value={size.width} onChange={updateSize("width")}
                                                    variant="outlined" label="Width" fullWidth InputProps={{
                                                    endAdornment: <InputAdornment position="end">px</InputAdornment>,
                                                }}/>
                                            </Grid>
                                            <Grid item md={6}>
                                                <TextField value={size.height} onChange={updateSize("height")}
                                                    variant="outlined" label="Height" fullWidth InputProps={{
                                                    endAdornment: <InputAdornment position="end">px</InputAdornment>,
                                                }}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Section>}
            {done && <Element name="preview">
                <Box my={4} display="flex" justifyContent="center">
                    <iframe title="Fatbike Conditions" src={url()} width={size.width} height={size.height} className={c.iframe}/>
                </Box>
            </Element>}
            <Snackbar open={copied} autoHideDuration={5000} onClose={() => setCopied(false)}
                anchorOrigin={{vertical: "top", horizontal: "right"}}>
                <Alert severity="success">
                    Widget code copied to clipboard.
                </Alert>
            </Snackbar>
        </>
    )
}

export default Builder;
