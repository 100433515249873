import {useEffect, useGlobal} from "reactn";

export const useRegions = () => {
    const [api] = useGlobal("api");
    const [, setRegion] = useGlobal("region");
    const [regions, setRegions] = useGlobal("regions");
    const [, setError] = useGlobal("error");

    useEffect(() => {
        if (regions && regions.length === 0 && api && setRegion && setRegions) {
            fetch(`${api}/regions`)
                .then(rsp => rsp.json())
                .then(data => {
                    setRegions(data.regions);
                    setRegion(data.regions[0]);
                })
                .catch(setError)
        }

    }, [api, regions, setRegion, setRegions, setError])

    return regions;
}

