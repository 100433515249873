import React from "reactn";
import Grid from "@material-ui/core/Grid";
import CodeIcon from '@material-ui/icons/Code';
import Box from "@material-ui/core/Box";
import Section, {SectionHeading} from "../util/section";
import {Body1} from "../util/text";
import {ReactComponent as Logo} from "../images/logos/logo.svg";


const Hero = () => {
    return (
        <Section name="hero">
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                    <Box p={10}>
                        <Logo/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SectionHeading title="Embed Conditions" icon={<CodeIcon/>}/>
                    <Body1>Use this page to build a widget that you can embed on your own website.</Body1>
                </Grid>
            </Grid>
        </Section>
    )
}

export default Hero;
