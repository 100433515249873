import React from 'reactn';
import addReactNDevTools from 'reactn-devtools';
import ReactDOM from 'react-dom';
import initState from './state';
import App from './app';
import fetchIntercept from 'fetch-intercept';
import reportWebVitals from './reportWebVitals';
require('typeface-roboto');

export const FB_TOKEN_HEADER = "X-CAMBA-FB-Token";
export const FB_TOKEN_KEY = "camba:fbToken";


fetchIntercept.register({
    request: (url, config) => {
        const token = localStorage.getItem(FB_TOKEN_KEY);
        if (token) {
            config = {...config};
            if (!config.headers) config.headers = {};
            config.headers[FB_TOKEN_HEADER] = token;
        }
        return [url, config];
    },

    response: (response) => {
        if (response.type === "cors") {
            if (response.headers.has(FB_TOKEN_HEADER)) {
                localStorage.setItem(FB_TOKEN_KEY, response.headers.get(FB_TOKEN_HEADER));
            }
        }
        return response;
    }
})

addReactNDevTools();
initState();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
