import React, {useGlobal, useEffect, useState, useDispatch} from 'reactn'
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import Top from './top'
import Login from "./login";
import Spinner from "../util/spinner";
import SEO from "../util/seo";

const AppPage = ({children}) => {
    const [api] = useGlobal("api");
    const [user] = useGlobal("user");
    const login = useDispatch("login");
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (api && login) {
            setLoading(true);
            fetch(`${api}/auth`)
                .then(rsp => {
                    if (rsp.status !== 200) {
                        throw Error(rsp.status);
                    }
                    return rsp;
                })
                .then(rsp => rsp.json())
                .then(user => {
                    login(user).then(() => setLoading(false));
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [api, login]);

    return (
        <>
            <SEO title="Fat Bike Grooming"/>
            <Top/>
            {isLoading && <Spinner />}
            {!isLoading && !user && <Login/>}
            {!isLoading && user &&
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {children}
              </MuiPickersUtilsProvider>
            }
        </>
    )
}

export default AppPage;
