import React, {useGlobal, useState, useDispatch, useEffect} from 'reactn'
import {formatDistance, parseISO, format} from 'date-fns';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import CloseIcon from '@material-ui/icons/Close'
import {Body1, Subtitle2} from '../util/text'
import Section, {SectionHeading} from '../util/section'
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";


const Advisory = () => {
    const [api] = useGlobal("api");
    const [open, setOpen] = useState(false);
    const [message,setMessage] = useState("");
    const [region,] = useGlobal("region");
    const [, setError] = useGlobal("error");
    const postAdvisory = useDispatch("postAdvisory");
    const clearAdvisory = useDispatch("clearAdvisory");


    useEffect(() => {
        if (api && setError) {
            fetch(`${api}/regions`)
                .then(rsp => rsp.json())
                .then(data => {
                    // setRegions(data.regions);
                    // setRegion(data.regions[0]);
                })
                .catch(setError)
        }

    }, [api, setError])

    const handlePost = () => {
        setOpen(false);

        const now = new Date();
        fetch(`${api}/regions/${region.id}/advisories`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({message, date: now.toISOString()})
        })
        .then(() => postAdvisory(region, message, now))
        .catch(setError)
    }

    const handleClear = () => {
        fetch(`${api}/regions/${region.id}/advisory`, {
            method: "DELETE"
        })
        .then(() => clearAdvisory(region))
        .catch(setError)
    }

    return (
        <Section name="advisory">
            <SectionHeading title="Advisory" icon={<ReportProblemOutlinedIcon/>}/>
            {region && !region.advisory &&
            <>
                <Grid container justify="space-between" alignItems="center" spacing={1}>
                   <Grid item>
                       <Body1>There is currently no advisory set for {region.name}.</Body1>
                   </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={() => setOpen(true)}>Post</Button>
                    </Grid>
                </Grid>
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>Post an Advisory</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter a message to post as an advisory for {region.name}.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            value={message}
                            onChange={evt => setMessage(evt.target.value)}
                            // margin="dense"
                            variant="outlined"
                            id="message"
                            label="Advisory Message"
                            rows={5}
                            multiline
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handlePost} color="primary" disabled={!message.trim()}>
                            Post
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
            }
            {region && region.advisory &&
            <Alert severity="warning" variant="outlined" action={
                <IconButton
                    color="inherit"
                    size="small"
                    onClick={handleClear}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }>
                <AlertTitle>Current Advisory</AlertTitle>
                {region.advisory}
                <Box mt={4}>
                    {region.advisory_date && <Subtitle2>Created by {`${region.advisory_author.first_name} ${region.advisory_author.last_name} 
                    ${formatDistance(parseISO(region.advisory_date), new Date())} ago on 
                    ${format(parseISO(region.advisory_date), "EEE, LLL do 'at' HH:mm")}`}</Subtitle2>}
                </Box>
            </Alert>
            }
        </Section>
    )
}

export default Advisory;
